<template>
	<div class="dropdown" :class="{ 'is-active': isDropdownActive }" v-on-clickaway="away">
		<div class="dropdown-trigger">
			<button
				@click="isDropdownActive = !isDropdownActive"
				class="button is-fullwidth"
				aria-haspopup="true"
				aria-controls="dropdown-menu">
				<span v-text="selectedOption ? selectedOption : 'Please select one'"></span>
				<span class="icon is-small">
					<i class="fas fa-angle-down" aria-hidden="true"></i>
				</span>
			</button>
		</div>

		<div class="dropdown-menu" id="dropdown-menu" role="menu">
			<div class="dropdown-content">
				<a
					@click="setSelectedOption(option)"
					v-for="option in options"
					:value="option.value"
					:key="option.value"
					class="dropdown-item">
					{{ option.text }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
	props: {
		options: {
            type: Array,
            required: true
		},
		activeOption: {
            type: String,
            required: false
        }
	},
	mixins: [ clickaway ],
	data() {
		return {
			isDropdownActive: false,
			selectedOption: this.activeOption
		}
	},
	watch: {
		activeOption: function () {
			this.selectedOption = this.activeOption
		}
	},
	methods: {
		away() {
			this.isDropdownActive = false;
		},
		setSelectedOption(option) {
			this.selectedOption = option.text;
			this.isDropdownActive = false;
			this.$emit('selected', option.text);
			this.$emit('id', option.value);
			this.$emit('change');
		}
	}
};
</script>

<style scoped lang="scss">
	.dropdown {
		width: 100%;

		.dropdown-trigger {
			width: 100%;
		}

		.button {
			display: flex;
			width: 100%;
			justify-content: space-between;
			border: none;
			box-shadow: none;
			color: $text;

			span:not(.icon) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&:hover {
				// color: $primary;

				span.icon {
					color: $link;
				}
			}

			span.icon {
				font-size: 18px;
			}
		}

		&.is-active .dropdown-menu {
			width: 100%;
			max-width: 100%;
			max-height: 400px;
			overflow-x: hidden;
			overflow-y: auto;
			box-shadow: $box-shadow;

			.dropdown-content {
				padding: 0;
				overflow: hidden;

				a {
					padding: 7px 15px;
					font-size: 14px;

					&:hover {
						background: $light;
					}

					+ a {
						border-top: 1px solid $border;
					}
				}
			}
		}
	}
</style>