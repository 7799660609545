<template>
	<div class="my-gifts">
		<div class="container is-max-desktop">
			<div class="columns is-full-height is-centered is-gapless is-desktop is-vcentered">
				<div class="column is-10-tablet is-11-desktop">
					<div class="columns is-mobile is-multiline">
					<div class="column is-full-mobile is-half-tablet is-5-desktop is-hidden-mobile">
							<div class="gift-preview">
								<div class="header">
									<div class="columns is-gapless is-mobile">
										<div class="column">
											<img
												v-if="theme == 'gyb'"
												src="@/assets/img/logo.svg"
												width="78"
												height="28"
												alt="GiftNFT"
											/>
											<img
												v-if="theme == 'gyb'"
												src="@/assets/img/gyb-themed-logo.svg"
												width="78"
												height="28"
												alt="GiftNFT"
											/>
                                            <img
												v-if="theme == 'meme'"
												src="@/assets/img/meme-themed-logo.svg"
												width="78"
												height="28"
												alt="GiftNFT"
											/>
										</div>
										<div class="column">
											<button class="button is-primary is-rounded is-outlined is-uppercase has-text-weight-bold">
												Claim
											</button>
										</div>
									</div>
								</div>
								<div class="asset-preview">
									<img :src="selectedAssetImgUrl" />
									<div v-if="selectedAssetTemplateMint || selectedAssetName" class="asset-name">
										<div class="columns is-mobile is-gapless">
											<div v-if="selectedAssetTemplateMint && domain == DOMAIN.WAX" class="column is-narrow">
												<div class="num" v-text="'#' + selectedAssetTemplateMint"></div>
											</div>
											<div v-if="selectedAssetName" class="column">
												<div :class="{ 'name': domain == !DOMAIN.MEME, 'name-center': domain == DOMAIN.MEME }" v-text="selectedAssetName"></div>
											</div>
										</div>
									</div>
								</div>
								<div class="msg-box">
									<p v-text="message"></p>
								</div>
							</div>
							<div class="preview-disclaimer">
								Gift NFT Preview /<br />This is what the gifted user will see
							</div>
						</div>
					<div class="column is-half-tablet is-7-desktop">
                        <div v-if="errors.length" class="notification is-danger">
                            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                        </div>
                            <!-- Confirmation Modal Starts-->
							<div class="confirm-send modal" :class="{ 'is-active' : showConfirmModal}">
								<div class="modal-background" @click="closeConfirmModal()"></div>
								<div class="modal-card">
									<header class="modal-card-head">
										<p class="modal-card-title has-text-weight-bold">
											Are you sure?
										</p>
										<button
											@click="closeConfirmModal()"
											class="delete"
											aria-label="close">
										</button>
									</header>
									<section class="modal-card-body">
										<div>
											This action is
											irreversable, so please make sure all
											the info is correct.
                                            <!-- <br/><br/>
                                            If gift isn't claimed within <strong>30 days</strong> we'll send it back to your wallet. -->
										</div>
									</section>
									<footer class="modal-card-foot">
										<button
											class="button is-primary"
											@click="send()">
											Confirm
										</button>
										<button
											@click="closeConfirmModal()"
											class="button is-text is-outlined">
											Cancel
										</button>
									</footer>
								</div>
							</div>
                            <!-- Confirmation Modal Ends-->
                        <!-- Loading Modal Starts-->
							<div class="confirm-loading modal" :class="{ 'is-active' : showLoadingModal}">
								<div class="modal-background"></div>
								<div class="modal-card">
									<section class="modal-card-body">
										<div class="has-text-centered">
											<div class="loading"></div>
											<h1>We're working on sending your Email's. This could take few minutes</h1>
										</div>
									</section>
								</div>
							</div>
                            <!-- Loading Modal Ends-->
                            <!-- Confirmation DIV Starts-->
							<div v-if="showConfirmation" class="gift-form-confirmation content">
								<h2>Email's have been sent!</h2>
								<p>
									User's should be getting an
									<strong>email</strong> shortly with
									instructions on how to 'claim' their new NFT.
								</p>
								<p class="has-text-weight-bold">
								</p>
                                <button
									@click="reload"
									class="create-new button is-medium is-rounded is-link">
									Send Another
								</button>
							</div>
                            <!-- Confirmation DIV Ends-->

                        <div v-else class="gift-form content">

						<h2>Mass Gifting</h2>

							<ValidationObserver v-slot="{ invalid }">
									<!-- <form> -->
										<div class="send-details">
											<div class="field is-horizontal">
												<div class="field-label is-normal">
													<label class="label">From</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider
															name="from"
															rules="required|max:64"
															v-slot="{ errors, classes }">
															<p class="control">
																<input
																	v-model="from"
																	class="input"
																	:class="classes"
																	type="text"
																	placeholder="Your name"
                                                                    />
															</p>
															<span class="help is-danger">
																{{ errors[0] }}
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div>
											<div class="field is-horizontal">
													<div class="field-label is-normal">
														<label class="label">Collection</label>
													</div>
													<div class="field-body">
														<div class="field">
															<div class="control">
																<collection-dropdown :options="availableCollections" :domain="domain" :domainSettings="domainSettings" :activeOption="collectionName" @selected="collectionName = $event" @change="onCollectionChange($event)"/>
															</div>
														</div>
													</div>
											</div>

											<div class="field is-horizontal">
													<div class="field-label is-normal">
														<label class="label">Template</label>
													</div>
													<div class="field-body">
														<div class="field">
															<div class="control">
																<template-dropdown :options="availableTemplates" :activeOption="templateOptionName" @selected="templateID = $event" @id="templateID = $event" @change="onTemplateChange($event)"/>
															</div>
														</div>
													</div>
											</div>

											<div class="field is-horizontal">
												<div class="field-label is-normal">
													<label class="label">Email Addresses</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider
                                                            rules="required"
															v-slot="{ errors, classes }">
															<p class="control">
																<textarea
																	name="email"
																	v-model="emailAddresses"
																	class="textarea"
																	:class="classes"
																	placeholder="Comma-Separated">
																</textarea>
															</p>
															<span class="help is-danger has-margin-top">
																{{ errors[0] }}
															</span>
                                                            <span v-if="emailCount" class="help has-text-white has-margin-top">
																<strong class="has-text-white"> Email Count: {{  emailCount }} </strong>
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div>

                                            <div class="field is-horizontal">
												<div class="field-label is-normal">
													<label class="label">Message</label>
												</div>
												<div class="field-body">
													<div class="field">
														<ValidationProvider
															rules="max:500"
															v-slot="{ errors, classes }">
															<p class="control">
																<textarea
																	name="message"
																	v-model="message"
																	class="textarea"
																	:class="classes"
																	placeholder="i.e. a gift from me, to you!">
																</textarea>
															</p>
															<span class="help is-danger has-margin-top">
																{{ errors[0] }}
															</span>
														</ValidationProvider>
													</div>
												</div>
											</div>
										</div>

										<div>
										<button
											class="send-btn button is-medium is-rounded is-link"
											@click="showConfirmationBox()" :disabled="invalid || showLoadingModal">
											Send
										</button>
										</div>
									<!-- </form> -->
								</ValidationObserver>
						
					</div>
				</div>
				</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
import UserGiftService from "../core/services/user-gift.service";
import MassEmailModel from "../core/models/mass-email.model";
import CollectionDropdown from '@/components/CollectionDropdown.vue';
import TemplateDropdown from '@/components/TemplateDropdown.vue';
import { DOMAIN } from '../core/models/constants'

export default {
	components: {
		CollectionDropdown,
		TemplateDropdown
	},
	props:{
        accountName: {
			type: String,
			required: true
        },
        domain:{
             type: Number,
             required: true
        },
        domainSettings:{
            type: Object
        },
        isAdmin:{
             type: Boolean,
             required: true
        },
    },
	watch: {
		accountName: function () {
			if (this.accountName) {
                this.getCollections()
            }
		},
        isAdmin: function(){
            if(!this.isAdmin){
                this.$router.push({
                    path: `/`,
                    query: { id: this.$route.query.id },
                });
            }
        },
	},
    computed:{
        emailCount:function () {
            return this.emailAddresses ? this.emailAddresses.split(',').length : ''
        }
    },
	data() {
		return {
			availableCollections: [],
			availableTemplates:[],
			theme: this.domainSettings.WebsiteName,
			templateOptionName: "",
			selectedAssetImgUrl: 'https://giftnft.s3.amazonaws.com/card-placeholder.jpg',
			selectedAssetTemplateMint: "",
			selectedAssetName: "",
            errors: [],
            showLoadingModal: false,
            showConfirmation: false,
            showConfirmModal: false,
            DOMAIN:DOMAIN,

			// form fields
			from: "",
			templateID: "",
			emailAddresses: "",
			collectionName: "",
            message:
			"A gift from me, to you! I really hope you enjoy this NFT. Cheers!",
		};
	},
	methods: {
        getCollections(){
            let service = new UserGiftService();
                service
				.getCollections(this.accountName)
				.then((response) => {
					this.availableCollections = response.data.collections
				})
        },
        closeConfirmModal() {
			this.showConfirmModal = false;
		},
        reload() {
			this.$router.go(this.$router.currentRoute);
		},
		onCollectionChange() {
			this.templateOptionName = 'Please select one'
			this.templateID = ''
			let service = new UserGiftService();
			service
				.getTemplates(this.collectionName)
				.then((response) => {
					this.availableTemplates = response.data.templates
				})
		},
		onTemplateChange(){
			var selectedTemplate = this.availableTemplates.find(x => x.value == this.templateID)
			this.templateOptionName = selectedTemplate.text
            this.selectedAssetImgUrl = selectedTemplate.data
            this.selectedAssetName = selectedTemplate.text
		},
        showConfirmationBox() {
			// show confirm box
			this.showConfirmModal = true;
		},
		send(){
            this.errors = []
            this.showLoadingModal = true
            this.showConfirmModal = false
            if(this.validateEmailCSV()){
            let model = new MassEmailModel();
			model.FromName = this.from
			model.EmailAddresses = this.emailAddresses
			model.TemplateID = this.templateID
			model.CollectionName = this.collectionName
			model.Message = this.message

			let service = new UserGiftService();
			service
				.sendMassEmail(model)
				.then((response) => {
                    if (response.data.isSuccess) {
                        this.showConfirmation = true;
                    }
                    else{
                        this.showConfirmation = false;
                        this.errors.push(response.data.errorMessage)
                    }
                    this.showLoadingModal = false
                    this.showConfirmation = true;
				})
            }
		},

        validateEmailCSV(){
            let isValid = true;
            let emailList = this.emailAddresses.split(',')
            if(emailList.length > 10000){
                this.errors.push(`Max Email Address's allowed: 10000`)
                this.showLoadingModal = false
                this.showConfirmModal = false
                return false;
            }
            let emailAddressRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
            emailList.every(x => {
                if(!emailAddressRegex.test(x)){
                    isValid = false;
                    this.errors.push(`Invalid Email Address: ${x}`)
                    this.showLoadingModal = false
                    this.showConfirmModal = false
                    return false;
                }
                else return true;
            })
            return isValid
        }
	},
	mounted() {
        if(!this.isAdmin){
                this.$router.push({
                    path: `/`,
                    query: { id: this.$route.query.id },
                });
            }
        if(this.accountName){
            this.getCollections()
        }
	},
};
</script>

<style scoped lang="scss">
$padding: 60px;

	.confirm-send.modal {
		.button.is-text {
			color: $danger;

			&:hover {
				background: inherit;
			}
		}
	}

.my-gifts {
	position: relative;
	z-index: 30;

	@media screen and (min-width: $desktop) {
		margin-top: $padding;
		margin-bottom: $padding * 2;
	}

	@media screen and (max-width: $desktop) {
		overflow-x: hidden;
		overflow-y: visible;
		padding: 40px 30px;
	}

	h2 {
		margin-bottom: 25px;
		color: $link;
	}

	.columns.is-full-height {
		min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$padding});

		@media screen and (max-width: 766px) {
			min-height: calc(100vh - #{$navbar-height} - 224px - 80px);
		}
	}
}
	.columns.is-full-height {
		min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$padding});

		@media screen and (max-width: 766px) {
			min-height: calc(100vh - #{$navbar-height} - 224px - 80px);
		}
	}

	.gift-preview {
		position: relative;
		margin-right: 30px;
		overflow: hidden;
		background: $white;
		border-radius: 5px;
		box-shadow: $box-shadow-large;

		// @media screen and (min-width: $tablet) and (max-width: $desktop) {
		//     margin-left: 30px;
		//     margin-right: 30px;
		// }

		.header {
			$size: 30px;
			padding: 10px 20px;
			border-bottom: 1px solid $border;

			img {
				display: inline-block;
				vertical-align: middle;
				width: auto;
				height: $size;
				margin-top: 1px;
			}

			.button {
				float: right;
				height: $size;
				border-radius: 1000px;
			}
		}

		.asset-preview {
			padding: 30px 50px;
			text-align: center;
			background: $light;
			overflow: hidden;

			img {
				display: inline-block;
				vertical-align: middle;
				-webkit-filter: drop-shadow(0 2px 3px rgba(black, 0.3));
				filter: drop-shadow(0 2px 3px rgba(black, 0.3));
				// box-shadow: 0 2px 3px rgba(black, 0.3);
			}

			.asset-name {
				margin-top: 15px;
				background: light;
				line-height: 1;

				.num,
				.name,
				.column {
					display: block;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.num {
					padding-right: 10px;
					color: $text;
					text-align: left;
				}

				.name {
					padding-left: 10px;
					color: $primary;
					text-align: right;
				}
                .name-center {
					padding-left: 10px;
					color: $primary;
					text-align: center;
				}
			}
		}

		.msg-box {
			padding: 15px 20px;
			border-top: 1px solid $border;

			p {
				margin: 0;
				color: $info;
				// font-weight: bold;
				// text-align: center;
			}
		}
	}
    .preview-disclaimer {
		margin: 15px 30px 0 0;
		font-size: 10px;
		text-transform: uppercase;
		color: rgba($white, 0.6);
		text-align: center;
		font-weight: bold;
		line-height: 1.2;
	}
	.gift-form,
	.gift-form-confirmation {
		// @media screen and (min-width: $tablet) and (max-width: $desktop) {
		//     margin-right: 30px;
		// }

		h2 {
			margin-bottom: 25px;
			color: $link;
		}

		p {
			margin-bottom: 30px !important;
			color: $white;

			strong {
				color: $white;
			}
		}

		.label {
			margin-bottom: 15px;
			color: $white;
			line-height: 1;
		}

		input,
		select,
		textarea {
			border: none;
			box-shadow: none !important;
		}

		.select {
			&:after {
				border-color: $primary !important;
			}

			&:hover:after {
				border-color: $link;
			}
		}

		.send-method {
			margin-bottom: 30px;

			.button {
				width: 100px;
				font-weight: bold;
				border: none !important;
				color: $text;

				&:hover:not(.is-selected) {
					border-color: inherit;
					color: $text;
				}

				&.is-selected {
					background: $primary;
					color: $white;
					border-color: $primary;
				}
			}
		}

		.send-details {
			position: relative;
			margin-bottom: 30px;
			padding: 20px;
			border: 2px solid $primary;
			border-radius: 5px;
			background: rgba($dark, 0.6);

			label.label {
				margin-bottom: 0;
				line-height: inherit;
			}

			> .field:last-child p.control {
				margin-bottom: 0 !important;
			}

			.field p.control {
				margin-bottom: 10px !important;
			}

            .field div.control {
				margin-bottom: 10px !important;
			}

			.field.has-addons .control:not(:last-child) {
				border-right: 2px solid $border;
			}

			input.invalid {
				border: solid 1px #ff654d;
			}

			textarea.invalid {
				border: solid 1px #ff654d;
			}

			.help {
				margin-top: 0;

				&.has-margin-top {
					margin-top: 10px;
				}
			}
		}

		button.send-btn,
		button.create-new {
			display: inline-block;
			margin-bottom: 80px;
			padding: 0px 30px;
			height: 46px;
			text-transform: uppercase;
			background: $gradient;
			background-repeat: no-repeat;
			font-weight: bold;
			font-size: $body-size;
			border: none;
			transition: all 0.2s linear;

			&:hover {
				border: none;
				background: $gradient;
				transform: translateY(1px);
			}

			&[disabled] {
				opacity: 1;
				// background: $border;
			}
		}

		.nft-selector {
			margin-top: 20px;

			@media screen and (max-width: 766px) {
				max-width: calc(100vw - 60px);
			}

			.vue-horizontal-list {
				margin: -24px 0 26px 0 !important;

				.item {
					position: relative;
					display: block;
					padding: 15px;
					border: 2px solid darken($dark, 5%);
					border-radius: 5px;
					background: rgba($dark, 0.6);
					cursor: pointer;
					overflow: hidden;

					.img-container {
						position: relative;
						width: 100%;
						height: 150px;
						overflow: hidden;
						box-sizing: border-box;
					}

					&.is-selected {
						border-color: $primary;

						.item-name {
							border-color: $primary;
							background: $primary;
							color: $white;
						}
					}

					img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						height: auto;
						max-height: 100%;
						max-width: 100%;
					}

					.item-name {
						margin-top: 15px;
						border: 1px solid darken($dark, 10%);
						background: darken($dark, 3%);
						// border: 1px solid $link;
						padding: 8px 10px;
						text-align: center;
						border-radius: 1000px;
						color: $white;
						text-transform: uppercase;
						font-size: 10px;
						font-weight: bold;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.item-num {
						position: absolute;
						display: inline-block;
						top: 5px;
						right: 5px;
						// background: darken($dark, 5%);
						border: 1px solid darken($dark, 10%);
						background: darken($dark, 3%);
						padding: 6px 10px;
						text-align: center;
						border-radius: 1000px;
						color: $white;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: bold;
						// box-shadow: $box-shadow;
					}
				}
			}
		}
        .confirm-loading {

		.modal-card {
			width: 340px;
			border-radius: 5px;

			h1 {
				margin-top: 10px;
				font-size: 18px;
				color: $text;
				font-weight: bold;
			}

			p {
				margin-top: 10px;
				color: $text;
			}

			.modal-card-body {
				padding: 40px 50px;
			}
		}
	}
	}
</style>
