import { render, staticRenderFns } from "./MyClaims.vue?vue&type=template&id=6188d682&scoped=true"
import script from "./MyClaims.vue?vue&type=script&lang=js"
export * from "./MyClaims.vue?vue&type=script&lang=js"
import style0 from "./MyClaims.vue?vue&type=style&index=0&id=6188d682&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6188d682",
  null
  
)

export default component.exports