<template>
	<div class="home">
		<div class="container is-max-desktop">
			<div class="columns is-centered is-gapless is-desktop">
				<div class="column is-10-tablet is-11-desktop">
					<div class="columns is-full-height is-vcentered is-gapless is-mobile is-multiline">
                        <div class="column is-full-mobile is-half-tablet is-5-desktop">
							<div class="card-container">
								<div
									v-for="(img, index) in homeCardImgUrls"
									v-bind:key="img"
									class="card"
									:class="[`card-${index + 1}`]"
								>
									<img :src="img" />
								</div>
							</div>
						</div>
						<div class="column is-full-mobile is-half-tablet is-7-desktop">
							<div class="text-box content">
								<h1>
									HOW IT WORKS
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    props: ["accountName"],
	data() {
		return {
            homeCardImgUrls: [],
		};
	},
	methods: {
	},
	mounted() {
	},
};
</script>

<style scoped lang="scss">
.home {
	position: relative;
	z-index: 30;

	@media screen and (max-width: $desktop) {
		padding: 40px 0;
	}

	@media screen and (max-width: 766px) {
		overflow-x: hidden;
		overflow-y: visible;
	}
}

@media screen and (min-width: $tablet) {
	.columns.is-full-height {
		min-height: calc(100vh - #{$navbar-height} - #{$footer-height});
	}
}

.card-container {
	position: relative;
	width: 300px;
	height: 500px;
	//background: rgba(white, 0.05);

	@media screen and (max-width: 766px) {
		width: 100%;
		height: 500px;
	}

	.card {
		position: absolute;
		display: block;
		box-shadow: $box-shadow;
		overflow: hidden;
		border-radius: 0;
		transform-style: preserve-3d;

		img {
			display: block;
			border: 5px solid $white;
			width: 100%;
			height: auto;
			max-width: 180px;

			@media screen and (max-width: 766px) {
				max-width: 200px;
			}
		}

		&.card-1 {
			top: 0;
			right: 0;
			transform: rotate(5deg) translatez(0); // translatez(0) fix for jagged edges on rotate
			outline: 1px solid transparent; // fix for jagged edges on rotate
			z-index: 3;

			@media screen and (max-width: 766px) {
				right: 30px;
			}
		}

		&.card-2 {
			top: 50%;
			left: 0;
			transform: translateY(-50%) rotate(-15deg) translatez(0); // translatez(0) fix for jagged edges on rotate
			outline: 1px solid transparent; // fix for jagged edges on rotate
			z-index: 2;
		}

		&.card-3 {
			bottom: 0;
			right: 0;
			transform: rotate(15deg) translatez(0); // translatez(0) fix for jagged edges on rotate
			outline: 1px solid transparent; // fix for jagged edges on rotate
			z-index: 1;
		}
	}
}

.text-box {
	position: relative;
	margin-left: 100px;

	@media screen and (max-width: 766px) {
		margin: 50px 60px 30px;
	}

	@media screen and (min-width: $tablet) and (max-width: $desktop) {
		margin-left: 80px;
	}

	.partner-logo {
		margin-bottom: 30px;

		img {
			max-height: 68px;
		}
	}

	h1 {
		position: relative;
		font-size: 32px;
		margin: 0 0 30px !important;
		color: $white;
	}

	p {
		margin-bottom: 40px !important;
		color: $white;
	}

	button {
		display: inline-block;
		padding: 0px 30px;
		height: 46px;
		text-transform: uppercase;
		background: $gradient;
		background-repeat: no-repeat;
		font-weight: bold;
		font-size: $body-size;
		border: none;
		transition: all 0.2s linear;

		&:hover {
			border: none;
			background: $gradient;
			transform: translateY(1px);
		}
	}

	.free-arrow {
		position: absolute;
		left: -110px;
		top: -30px;

		@media screen and (max-width: 766px) {
			display: none;
			// left: 0;
			// top: -90px;
		}

		img {
			max-width: 90px;
		}
	}
}
</style>
