<template>
	<div class="my-claims">
		<div class="container is-max-desktop">
			<div class="columns is-full-height is-centered is-gapless is-desktop">
				<div class="column is-12-tablet is-11-desktop">
					<div class="gift-form content">
						<h2>Gift a NFT</h2>
						<div v-if="errorMessage" class="notification is-danger">
							<span>{{ errorMessage }}</span>
						</div>

						<div class="send-details">
							<div>
								<div class="columns is-vcentered is-centered">
									<div class="column has-text-right">
										<a
											class="button is-rounded is-outlined has-text-weight-bold"
											@click="showConnectionModal = true"
											v-if="!sendGridConnected"
											><i class="far fa-link pr-1"></i>Connect Sendgrid</a
										>
										<a
											class="button is-rounded is-outlined has-text-weight-bold"
											@click="updateSendgridConnection(true)"
											v-else
											><i class="far fa-unlink pr-1"></i>Disconnect</a
										>
									</div>
								</div>
								<!-- <div class="columns is-mobile is-centered">
									<div class="column has-text-right">
										<a
											class="
												button
												is-rounded is-outlined
												has-text-weight-bold has-text-right
											"
											@click="updateSendgridConnection(true)"
											><i class="far fa-unlink pr-1"></i>Disconnect</a
										>
									</div>
								</div> -->
								<div class="is-divider mt-0"></div>
								<ValidationObserver v-slot="{ invalid }" ref="obs1">
									<div>
										<div class="field is-horizontal">
											<div class="field-label is-normal">
												<label class="label">From</label>
											</div>
											<div class="field-body">
												<div class="field">
													<ValidationProvider
														name="from"
														rules="required|max:64"
														v-slot="{ errors, classes }"
													>
														<p class="control">
															<input
																v-model="from"
																class="input"
																:class="classes"
																type="text"
																placeholder="Your name"
															/>
														</p>
														<span class="help is-danger">
															{{ errors[0] }}
														</span>
													</ValidationProvider>
												</div>
											</div>
										</div>
										<div class="field is-horizontal">
											<div class="field-label is-normal">
												<label class="label">Message</label>
											</div>
											<div class="field-body">
												<div class="field">
													<ValidationProvider
														:rules="`max:${messageMaxLength}`"
														v-slot="{ errors, classes }"
													>
														<p class="control">
															<textarea
																name="message"
																v-model="message"
																class="textarea"
																:class="classes"
																placeholder="i.e. a gift from me, to you!"
															>
															</textarea>
														</p>
														<span class="help is-danger has-margin-top">
															{{ errors[0] }}
														</span>
													</ValidationProvider>
												</div>
											</div>
										</div>
										<div class="is-divider"></div>
										<div class="field is-horizontal" v-if="sendGridConnected">
											<div class="field-label is-normal">
												<label class="label">Sendgrid Lists</label>
											</div>
											<div class="field-body">
												<div class="field">
													<div class="columns is-vcentered">
														<div class="column is-8">
															<div class="control">
																<collection-dropdown
																	:options="availableList"
																	:domain="domain"
																	:domainSettings="domainSettings"
																	@selected="sendgridListID = $event"
																/>
															</div>
														</div>
														<div class="column has-text-right">
															<button
																class="
																	send-btn
																	button
																	is-medium is-rounded is-link
																"
																:class="{ 'is-loading': loadingContacts }"
																@click="getSendgridContacts"
																:disabled="!sendgridListID"
															>
																<i class="far fa-search"></i>
																Search
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="field is-horizontal">
											<div class="field-label is-normal">
												<label class="label"
													>To
													<span data-tooltip="Email Addresses comma-separated">
														<i class="far fa-question-circle"></i>
													</span>
												</label>
											</div>
											<div class="field-body">
												<div class="field">
													<ValidationProvider
														rules="required"
														v-slot="{ errors, classes }"
													>
														<p class="control">
															<textarea
																name="email"
																v-model="emailAddresses"
																class="textarea"
																:class="classes"
																placeholder="Comma-Separated"
															>
															</textarea>
														</p>
														<span class="help is-danger has-margin-top">
															{{ errors[0] }}
														</span>
														<span
															v-if="emailCount"
															class="help has-text-white has-margin-top"
														>
															<strong class="has-text-white">
																Email Count: {{ emailCount }}
															</strong>
														</span>
													</ValidationProvider>
												</div>
											</div>
										</div>
										<div class="is-divider"></div>
										<div class="field is-horizontal">
											<div class="field-label is-normal">
												<label class="label"
													>Template IDs
													<span
														data-tooltip="Enter upto 5 Template IDs comma-separated (23626,21620)"
													>
														<i class="far fa-question-circle"></i>
													</span>
												</label>
											</div>
											<div class="field-body">
												<div class="field">
													<div class="columns is-vcentered">
														<div class="column is-8">
															<div class="control">
																<input
																	v-model="templateIDs"
																	class="input"
																	type="text"
																	placeholder="23626"
																/>
															</div>
														</div>
														<div class="column has-text-right">
															<button
																class="
																	send-btn
																	button
																	is-medium is-rounded is-link
																"
																:class="{ 'is-loading': loadingAssets }"
																@click="getAssets"
																:disabled="!templateIDs"
															>
																<i class="far fa-search"></i>
																Search
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="field is-horizontal">
											<div class="field-label is-normal">
												<label class="label">Assets</label>
											</div>
											<div class="field-body">
												<div class="field">
													<ValidationProvider
														rules="required"
														v-slot="{ errors, classes }"
													>
														<p class="control">
															<textarea
																name="assets"
																v-model="assets"
																class="textarea"
																:class="classes"
																placeholder="Comma-Separated"
															>
															</textarea>
														</p>
														<span class="help is-danger has-margin-top">
															{{ errors[0] }}
														</span>
														<span
															v-if="assetsCount"
															class="help has-text-white has-margin-top"
														>
															<strong class="has-text-white">
																Assets Count: {{ assetsCount }}
															</strong>
														</span>
													</ValidationProvider>
												</div>
											</div>
										</div>
										<div class="is-divider"></div>
										<!-- <div class="field is-horizontal">
											<div class="field-label is-normal">
												<label class="label">Batch Size</label>
											</div>
											<div class="field-body">
												<div class="field">
													<div class="control">
														<collection-dropdown
															:options="batchOptions"
															:domain="domain"
															:domainSettings="domainSettings"
															:activeOption="batchSize"
															@selected="batchSize = $event"
														/>
													</div>
												</div>
											</div>
										</div> -->
									</div>
									<div class="field">
										<div class="columns is-vcentered has-text-right">
											<div class="column ml-5">
												<div class="control">
													<ValidationProvider
														:rules="{ required: { allowFalse: false } }"
														v-slot="{ classes, errors }"
													>
														<label class="checkbox has-text-white">
															<input
																type="checkbox"
																:class="classes"
																v-model="terms"
															/>
															I agree to the
															<a
																href="https://www.bountyblok.io/terms-conditions"
																target="_blank"
																>terms and conditions</a
															>
														</label>
														<span
															class="help is-danger has-margin-top"
															v-if="errors && errors.length > 0"
														>
															You must agree to Terms & Conditions
														</span>
													</ValidationProvider>
												</div>
											</div>

											<div class="column has-text-right">
												<button
													@click="buildBatches()"
													:disabled="disableBatchBtn"
													class="send-btn button is-medium is-rounded is-link"
												>
													<i class="far fa-layer-group"></i> Create Batches
												</button>
											</div>
										</div>
									</div>

									<batches
										v-if="!invalid"
										ref="batches"
										:accountName="accountName"
										:permission="permission"
										:emailAddresses="emailAddresses"
										:batchSize="batchSize"
										:assets="assets"
										:domain="domain"
										:domainSettings="domainSettings"
										@show-batch-btn="toggleBatchButton"
									/>
								</ValidationObserver>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Connection Modal Starts-->
		<div
			class="confirm-send modal"
			:class="{ 'is-active': showConnectionModal }"
		>
			<div class="modal-background" @click="closeConnectionModal()"></div>
			<div class="modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title has-text-weight-bold">Connect SendGrid</p>
					<button
						@click="closeConnectionModal()"
						class="delete"
						aria-label="close"
					></button>
				</header>
				<section class="modal-card-body">
					<div class="has-text-centered">
						<div class="field-body">
							<div class="field">
								<ValidationProvider
									name="totwitter"
									:rules="'required'"
									v-slot="{ errors, classes }"
								>
									<p class="control">
										<input
											v-model="sendgridApiKey"
											class="input"
											:class="classes"
											type="text"
											placeholder="SG.Ohf4iR7STc2Jl2zBJp0RzQ.H4yG20DrPizvEKyqxxHYBJWri7QZ8B8666k1kTXU68"
										/>
									</p>
									<p class="help has-text-weight-semibold">
										Enter your sendgrid API key with Marketing permission
										enabled. If you don't have one simply create one
										<a
											target="_blank"
											href="https://app.sendgrid.com/settings/api_keys"
											>here</a
										>
									</p>
									<span class="help is-danger">
										{{ errors[0] }}
									</span>
								</ValidationProvider>
							</div>
						</div>
					</div>
				</section>
				<footer class="modal-card-foot">
					<button
						class="
							button
							is-primary is-rounded is-outlined is-uppercase
							has-text-weight-bold
						"
						:disabled="!sendgridApiKey"
						@click="updateSendgridConnection(false)"
					>
						Connect
					</button>
				</footer>
			</div>
		</div>
		<!-- Connection Modal Ends-->
	</div>
</template>

<script>
import axios from "axios";
import Batches from "@/components/Batches.vue";
import GiftLinkService from "../core/services/gift-link.service";
import CollectionDropdown from "@/components/CollectionDropdown.vue";

export default {
	components: {
		Batches,
		CollectionDropdown,
	},
	props: {
		accountName: {
			type: String,
			required: true,
		},
		permission: {
			type: String,
			required: true,
		},
		domain: {
			type: Number,
			required: true,
		},
		domainSettings: {
			type: Object,
		},
		isAdmin: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		emailCount: function () {
			return this.emailAddresses ? this.emailAddresses.split(",").length : "";
		},
		assetsCount: function () {
			return this.assets ? this.assets.split(",").length : "";
		},
	},
	watch: {
		accountName: function () {
			if (!this.accountName) {
				this.$refs.batches.batchData = [];
			}
		},
		isAdmin: function () {
			if (!this.isAdmin) {
				this.$router.push({
					path: `/`,
					query: { id: this.$route.query.id },
				});
			}
		},
	},
	data() {
		return {
			vueAppUrl: process.env.VUE_APP_URL,
			waxUrl: process.env.VUE_APP_WAX_URL,
			theme: this.domainSettings.WebsiteName,
			showConnectionModal: false,
			sendgridApiKey: "",
			sendGridConnected: false,
			showBatches: true,
			errorMessage: "",
			// form fields
			from: "",
			emailAddresses: "",
			assets: "",
			message:
				"A gift from me, to you! I really hope you enjoy this NFT. Cheers!",
			messageMaxLength: "500",
			batchSize: "50",
			batchOptions: [
				{ assetsCount: null, text: "5", value: "5" },
				{ assetsCount: null, text: "10", value: "10" },
				{ assetsCount: null, text: "25", value: "25" },
				{ assetsCount: null, text: "50", value: "50" },
			],
			availableList: [],
			sendgridListID: "",
			loadingContacts: false,
			loadingAssets: false,
			templateIDs: "",
			disableBatchBtn: false,
			terms: false,
		};
	},
	methods: {
		closeConnectionModal() {
			this.showConnectionModal = false;
		},
		connect() {
			this.sendGridConnected = true;
			this.closeConnectionModal();
		},
		buildBatches() {
			this.$refs.obs1.validate();
			if (!localStorage.getItem("userGuid")) {
				this.errorMessage = "You are not logged in";
				return;
			}
			if (!this.from) {
				return;
			}
			if (!this.terms) {
				return;
			}
			this.$refs.batches.buildBatches();
		},
		async emailBlast(to, keys, txID) {
			let emailBlastModel = {
				FromName: this.from,
				To: to,
				Keys: keys,
				UserID: localStorage.getItem("userGuid"),
				TxID: txID,
				Message: this.message,
			};
			let service = new GiftLinkService();
			return await service.emailBlast(emailBlastModel);
		},
		updateSendgridConnection(disconnect) {
			axios
				.post(`${process.env.VUE_APP_BASE_API_URL}members/updatesendgrid`, {
					userID: localStorage.getItem("userGuid"),
					sendgridApiKey: disconnect ? null : this.sendgridApiKey,
				})
				.then(() => {
					this.sendGridConnected = true;
					this.closeConnectionModal();
					disconnect
						? localStorage.setItem("isSendgridConnected", false)
						: localStorage.setItem("isSendgridConnected", true);
					this.sendGridConnected = disconnect ? false : true;
					if (this.sendGridConnected) {
						this.getSendgridLists();
					}
				});
		},
		getSendgridLists() {
			this.errorMessage = "";
			let service = new GiftLinkService();
			service.getSendgridLists(localStorage.getItem("userGuid")).then((res) => {
				try {
					if (res.status == 200) {
						if (res.data.isSuccess) {
							this.availableList = res.data.list;
						} else {
							this.errorMessage = res.data.errorMessage;
						}
					}
				} catch (e) {
					this.errorMessage = e;
				}
			});
		},
		getSendgridContacts() {
			this.errorMessage = "";
			this.loadingContacts = true;
			let service = new GiftLinkService();
			service
				.getSendgridContacts(
					localStorage.getItem("userGuid"),
					this.sendgridListID
				)
				.then(
					(res) => {
						try {
							if (res.status == 200) {
								this.emailAddresses = res.data.emailAddresses;
							}
							this.loadingContacts = false;
						} catch (e) {
							this.errorMessage = e;
							this.loadingContacts = false;
						}
					},
					(err) => {
						this.errorMessage = err;
						this.loadingContacts = false;
					}
				);
		},
		getAssets() {
			this.errorMessage = "";
			this.loadingAssets = true;
			let service = new GiftLinkService();
			service
				.getAssetsByTemplate(this.accountName, this.templateIDs)
				.then((res) => {
					try {
						if (res.status == 200 && res.data.assets) {
							this.assets = res.data.assets;
						} else {
							this.errorMessage =
								"No Assets found. Please enter valid Template IDs";
						}
						this.loadingAssets = false;
					} catch (e) {
						this.errorMessage = e;
						this.loadingAssets = false;
					}
				})
				.catch((err) => {
					this.errorMessage = err;
					this.loadingAssets = false;
				});
		},
		toggleBatchButton(e) {
			this.disableBatchBtn = e;
		},
	},
	mounted() {
		// if not logged in
		if (!localStorage.getItem("userGuid")) {
			this.$router.push(`/?id=${this.$route.query.id}`);
		}
		if (!this.isAdmin) {
			this.$router.push({
				path: `/`,
				query: { id: this.$route.query.id },
			});
		}
		//check if user has sendgrid connected already
		const sendgridConnectedToken = localStorage.getItem("isSendgridConnected");
		if (sendgridConnectedToken) {
			if (sendgridConnectedToken === "true") {
				this.sendGridConnected = true;
				this.getSendgridLists();
			}
		}
	},
};
</script>

<style scoped lang="scss">
$padding: 60px;

.my-claims {
	position: relative;
	z-index: 30;

	@media screen and (min-width: $desktop) {
		margin-top: $padding;
		margin-bottom: $padding * 2;
	}

	@media screen and (max-width: $desktop) {
		overflow-x: hidden;
		overflow-y: visible;
		padding: 40px 30px;
	}

	h2 {
		margin-bottom: 25px;
		color: $link;
	}

	.columns.is-full-height {
		min-height: calc(
			100vh - #{$navbar-height} - #{$footer-height} - #{$padding}
		);

		@media screen and (max-width: 766px) {
			min-height: calc(100vh - #{$navbar-height} - 224px - 80px);
		}
	}
	.gift-form,
	.gift-form-confirmation {
		// @media screen and (min-width: $tablet) and (max-width: $desktop) {
		//     margin-right: 30px;
		// }

		h2 {
			margin-bottom: 25px;
			color: $link;
		}

		p {
			margin-bottom: 30px !important;
			color: $white;

			strong {
				color: $white;
			}
		}

		.label {
			margin-bottom: 15px;
			color: $white;
			line-height: 1;
		}

		input,
		select,
		textarea {
			border: none;
			box-shadow: none !important;
		}

		.select {
			&:after {
				border-color: $primary !important;
			}

			&:hover:after {
				border-color: $link;
			}
		}

		.send-method {
			margin-bottom: 30px;

			.button {
				width: 100px;
				font-weight: bold;
				border: none !important;
				color: $text;

				&:hover:not(.is-selected) {
					border-color: inherit;
					color: $text;
				}

				&.is-selected {
					background: $primary;
					color: $white;
					border-color: $primary;
				}
			}
		}

		.send-details {
			position: relative;
			margin-bottom: 30px;
			padding: 20px;
			border: 2px solid $primary;
			border-radius: 5px;
			background: rgba($dark, 0.6);

			label.label {
				margin-bottom: 0;
				line-height: inherit;
			}

			> .field:last-child p.control {
				margin-bottom: 0 !important;
			}

			.field p.control {
				margin-bottom: 10px !important;
			}

			.field div.control {
				margin-bottom: 10px !important;
			}

			.field.has-addons .control:not(:last-child) {
				border-right: 2px solid $border;
			}

			input.invalid {
				border: solid 1px #ff654d;
			}

			textarea.invalid {
				border: solid 1px #ff654d;
			}

			.help {
				margin-top: 0;

				&.has-margin-top {
					margin-top: 10px;
				}
			}
		}

		button.send-btn,
		button.create-new {
			display: inline-block;
			//margin-bottom: 80px;
			padding: 0px 30px;
			height: 40px;
			margin-bottom: 10px;
			text-transform: uppercase;
			background: $gradient;
			background-repeat: no-repeat;
			font-weight: bold;
			font-size: $body-size;
			border: none;
			transition: all 0.2s linear;

			&:hover {
				border: none;
				background: $gradient;
				transform: translateY(1px);
			}

			&[disabled] {
				opacity: 1;
				// background: $border;
			}
		}

		.nft-selector {
			margin-top: 20px;

			@media screen and (max-width: 766px) {
				max-width: calc(100vw - 60px);
			}

			.vue-horizontal-list {
				margin: -24px 0 26px 0 !important;

				.item {
					position: relative;
					display: block;
					padding: 15px;
					border: 2px solid darken($dark, 5%);
					border-radius: 5px;
					background: rgba($dark, 0.6);
					cursor: pointer;
					overflow: hidden;

					.img-container {
						position: relative;
						width: 100%;
						height: 150px;
						overflow: hidden;
						box-sizing: border-box;
					}

					&.is-selected {
						border-color: $primary;

						.item-name {
							border-color: $primary;
							background: $primary;
							color: $white;
						}
					}

					img {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						height: auto;
						max-height: 100%;
						max-width: 100%;
					}

					.item-name {
						margin-top: 15px;
						border: 1px solid darken($dark, 10%);
						background: darken($dark, 3%);
						// border: 1px solid $link;
						padding: 8px 10px;
						text-align: center;
						border-radius: 1000px;
						color: $white;
						text-transform: uppercase;
						font-size: 10px;
						font-weight: bold;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.item-num {
						position: absolute;
						display: inline-block;
						top: 5px;
						right: 5px;
						// background: darken($dark, 5%);
						border: 1px solid darken($dark, 10%);
						background: darken($dark, 3%);
						padding: 6px 10px;
						text-align: center;
						border-radius: 1000px;
						color: $white;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: bold;
						// box-shadow: $box-shadow;
					}
				}
			}
		}
	}
}
</style>