
import {ACTIONTYPE} from './constants'

export default class BatchModel {
    constructor() {
        this.id= 0;
        this.totalAccounts= 0;
        this.actions= [];
        this.transferActions = [];
        this.sent= false;
        this.sending= false;
        this.isSuccess= false;
        this.error= null;
        this.showResend= false;
        this.resendInterval= null;
        this.loadingSubject = false
        this.txID= null
        this.transferTxID= null
        this.accounts=[]
        this.assets= []
        this.actiontype = ACTIONTYPE.AATRANSFER
        this.keys = []
        this.linksCreated = false
        this.emailSent = false;
        this.assetsTransfered = false
        this.showTransferResend = false
        this.transferBatchSent = false
    }
}
