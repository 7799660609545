<template>
	<div class="claim">
		<div class="container is-max-desktop">
			<div
				class="
					columns
					is-full-height
					is-centered
					is-gapless
					is-mobile
					is-desktop
					is-vcentered
				"
			>
				<div class="column is-10-tablet is-11-desktop">
					<div v-if="errorMessage" class="notification is-danger">
						<p>{{ errorMessage }}</p>
						<p>Buy can buy resources from <a target="_blank" :href="'https://waxblock.io/account/' + accountName">here</a></p>
					</div>
					<div class="columns is-vcentered is-mobile is-multiline">
						<div class="column is-full-mobile is-half-tablet is-5-desktop">
							<div class="gift-preview">
								<div class="header">
									<div class="columns is-gapless is-mobile">
										<div class="column">
											<img
												:src="$parent.getLogoUrl()"
												width="78"
												height="28"
												alt="GiftNFT"
											/>
										</div>
										<div class="column">
											<i class="far fa-badge-check"></i>
										</div>
									</div>
								</div>
								<div class="asset-preview">
									<img v-if="!hasVideo" :src="assetImgUrl" />
									<video autoplay loop muted playsinline v-if="hasVideo">
										<source :src="assetImgUrl" />
										Your browser does not support the video tag.
									</video>
									<div v-if="assetTemplateMint || assetName" class="asset-name">
										<div class="columns is-mobile is-gapless">
											<div v-if="assetTemplateMint" class="column is-narrow">
												<div class="num" v-text="'#' + assetTemplateMint"></div>
											</div>
											<div v-if="assetName" class="column">
												<div
													:class="{
														name: assetTemplateMint,
														'name-center': !assetTemplateMint,
													}"
													class="name"
													v-text="assetName"
												></div>
											</div>
										</div>
									</div>
								</div>
								<div v-if="userGift.message" class="msg-box">
									<p v-text="userGift.message"></p>
									<!-- <p v-text="qrCampaignMsg"></p> -->
								</div>
								<!-- <div class="msg-box">
									<div class="has-text-weight-bold">Transaction Id:</div>
									<div>{{ userGift.message }}</div>
								</div> -->
							</div>
						</div>

						<div class="column is-full-mobile is-half-tablet is-7-desktop">
							<!-- Confirmation Modal Starts-->
							<div
								class="confirm-send modal"
								:class="{ 'is-active': showConfirmModal }"
							>
								<div
									class="modal-background"
									@click="closeConfirmModal()"
								></div>
								<div class="modal-card">
									<header class="modal-card-head">
										<p class="modal-card-title has-text-weight-bold">
											Claim NFT
										</p>
										<button
											@click="closeConfirmModal()"
											class="delete"
											aria-label="close"
										></button>
									</header>
									<section class="modal-card-body">
										<div v-if="domain == DOMAIN.WAX">
											<p v-if="!giftClient">
												<strong
													>To 'claim' your NFT, simply login to your WAX account
													using the button below.</strong
												>
												Note, if you don't have one, you'll be given the option
												to create an account.
											</p>
											<p
												v-if="
													giftClient && !giftClient.claimConfirmationModalText
												"
											>
												<strong
													>To 'claim' your NFT, simply login to your WAX account
													using the button below.</strong
												>
												Note, if you don't have one, you'll be given the option
												to create an account.
											</p>
											<p
												v-if="
													giftClient && giftClient.claimConfirmationModalText
												"
											>
												{{ giftClient.claimConfirmationModalText }}
											</p>
										</div>
										<div
											v-else-if="
												domain == DOMAIN.GYB ||
												domain == DOMAIN.MEME ||
												domain == DOMAIN.NEWDEX
											"
										>
											<p v-if="!giftClient">
												<strong
													>To 'claim' your NFT, simply login to your METAMASK
													account using the button below.</strong
												>
												Note, if you don't have metamask installed, you can
												download from
												<a
													href="https://metamask.io/download.html"
													target="_blank"
													>here</a
												>.
											</p>
											<p
												v-if="
													giftClient && !giftClient.claimConfirmationModalText
												"
											>
												<strong
													>To 'claim' your NFT, simply login to your ARKANE
													account using the button below.</strong
												>
												Note, if you don't have one, you'll be given the option
												to create an account.
											</p>
											<p
												v-if="
													giftClient && giftClient.claimConfirmationModalText
												"
											>
												{{ giftClient.claimConfirmationModalText }}
											</p>
										</div>
									</section>
									<footer class="modal-card-foot">
										<button
											class="
												button
												is-primary is-rounded is-outlined is-uppercase
												has-text-weight-bold
											"
											@click="claim()"
										>
											{{ getClaimBtnText() }}
										</button>
									</footer>
								</div>
							</div>
							<!-- Confirmation Modal Ends-->

							<!-- Loading Modal Starts-->
							<div
								class="confirm-loading modal"
								:class="{ 'is-active': showLoadingModal }"
							>
								<div class="modal-background" @click="closeLoadingModal"></div>
								<div class="modal-card">
									<section class="modal-card-body">
										<div class="has-text-centered">
											<div class="loading"></div>
											<h1>We're working on sending your NFT.</h1>
											<p>Please confirm the transaction in your wallet.</p>
										</div>
									</section>
								</div>
							</div>
							<!-- Loading Modal Ends-->

							<div v-if="linkID && !giftClaimed" class="claim-nft content">
								<h2>You have been gifted this awesome NFT, enjoy!</h2>
								<div class="sent-from">
									<!-- <p>
										<span>To:</span>{{ userGift.toEmail }}{{ userGift.toPhone }}
									</p> -->
									<p><span>From:</span>{{ userGift.fromName }}</p>
								</div>
								<label class="label">Message</label>
								<p
									v-if="giftClient && giftClient.staticEmailMessage"
									v-text="giftClient.staticEmailMessage"
									class="is-italic"
								></p>
								<p v-text="userGift.message" class="is-italic"></p>
								<button
									@click="showConfirmationModal()"
									class="send-btn button is-medium is-rounded is-link"
								>
									Claim NFT
								</button>
							</div>

							<div
								v-if="qrCampaignTemplateID && !giftClaimed"
								class="claim-nft content"
							>
								<h2 v-if="!isPrivate">
									You have been gifted this awesome NFT, enjoy!
								</h2>
								<div class="sent-from">
									<p><span>From:</span>{{ qrCampaignFromName }}</p>
								</div>
								<div class="sent-from" v-if="mediaUrl">
									<!-- <div class="column is-full-mobile is-half-tablet is-5-desktop" v-if="!isVideo"> -->
									<img :src="mediaUrl" v-if="!isVideo" style="max-width: 68%" />
									<!-- </div> -->
									<video autoplay loop muted playsinline v-if="isVideo">
										<source :src="mediaUrl" />
										Your browser does not support the video tag.
									</video>
								</div>
								<label v-if="qrCampaignMsg" class="label">Message</label>
								<p
									v-if="qrCampaignMsg"
									v-html="qrCampaignMsg"
									class="is-italic"
								></p>
								<button
									:disabled="showLoadingModal"
									@click="showConfirmationModal()"
									class="send-btn button is-medium is-rounded is-link"
								>
									{{ isPrivate ? "Claim Now" : "Claim Free NFT" }}
								</button>
							</div>

							<div v-if="giftClaimed" class="claim-nft content">
								<h2 v-if="isSuccess">Gift has been claimed!</h2>
								<h2 v-if="!isSuccess">Oops!</h2>

								<p v-if="isSuccess">
									You have successfully claimed the gift from
									<strong>{{ userGift.fromName }}</strong>
									<strong v-if="qrCampaignFromName">{{
										qrCampaignFromName
									}}</strong>.
									<span v-if="waxTxUrl">
										<a target="_blank" :href="waxTxUrl"
											> View the blockchain transaction here</a
										>
									</span>
								</p>

								<a
									v-if="isSuccess && !isPack"
									class="
										button
										is-link is-rounded is-outlined is-uppercase
										has-text-weight-bold
									"
									target="_blank"
									:href="getExplorerUrl()"
								>
									See My Collections
								</a>

								<a
									v-if="isSuccess && isPack"
									class="
										button
										is-link is-rounded is-outlined is-uppercase
										has-text-weight-bold
									"
									target="_blank"
									:href="giftClient.unpackUrl"
								>
									Click To Open
								</a>

								<p v-if="!isSuccess">
									{{ errorMessage }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GiftLinkService from "../core/services/gift-link.service";
import { ASSETTYPE, DOMAIN } from "../core/models/constants";
const ecc = require("eosjs-ecc");

export default {
	props: {
		accountName: {
			type: String,
			required: true,
		},
		giftClient: {
			type: Object,
		},
		domain: {
			type: Number,
			required: true,
		},
		domainSettings: {
			type: Object,
		},
		permission: {
			type: String,
			required: true,
		},
	},
	watch: {
		accountName: function () {
			if (this.claimGift) {
				if (this.linkID) {
					this.transfer(true);
				} else if (this.qrCampaignTemplateID) {
					//this.mintQRCampaignGift(true);
				}
			}
		},
		giftClient: function () {
			//   if(this.userGift.assetID){
			//     this.setIsPack(this.userGift.assetID,this.giftClient.allowedTemplates)
			//   }
		},
	},
	data() {
		return {
			errors: [],
			message:
				"A gift from me, to you! I really hope you enjoy this NFT. Cheers!",
			assetImgUrl: require("@/assets/img/card-placeholder.jpg"),
			assetTemplateMint: "",
			assetName: "",
			linkID: null,
			giftClaimed: false,
			userGift: {},
			isSuccess: false,
			errorMessage: null,
			claimGift: false,
			waxTxUrl: null,
			giftClaimDate: null,
			waxOpenPackUrl: null,
			atomicHubInventory: "https://wax.atomichub.io/profile/",
			simpleAssetInventory: "https://wax.simplemarket.io/explorer/",
			isPack: false,
			showConfirmModal: false,
			confirmationModalText:
				"To 'claim' your NFT, simply login to your WAX account using the button below. Note, if you don't have one, you'll be given the option to create an account.",
			assetType: ASSETTYPE.AA,
			DOMAIN: DOMAIN,
			theme: this.domainSettings.WebsiteName,
			qrCampaignTemplateID: null,
			qrCampaignMsg: null,
			qrCampaignFromName: null,
			mediaUrl: null,
			isVideo: false,
			showLoadingModal: false,
			campaignSource: "",
			hasVideo: false,
			isMobileDevice: false,
			key: "",
			singature: "",
		};
	},
	methods: {
		setIsPackByCollection(collection, allowedCollection) {
			if (!collection || !allowedCollection) {
				return;
			}
			if (collection == allowedCollection) {
				this.isPack = true;
			}
		},
		setIsPackByTemplate(templateID, templates) {
			if (!templates || !templateID) {
				return;
			}
			let templatesArr = templates.split(",");
			let index = templatesArr.indexOf(templateID);
			if (index >= 0) {
				this.isPack = true;
			}
		},
		closeLoadingModal() {
			this.showLoadingModal = false;
		},
		showConfirmationModal() {
			this.showConfirmModal = true;
		},
		async claim() {
			this.errors = [];
			this.errorMessage = "";
			this.showConfirmModal = false;
			this.claimGift = true;
			if (!this.accountName || (!this.linkID && !this.qrCampaignTemplateID)) {
				await this.$parent.childlogin(this.$route.name);
			} else {
				this.showLoadingModal = true;
				await this.transfer(true);
			}
		},
		async transfer(transferAsset = false) {
			if (!transferAsset) {
				return;
			}
			if (!this.accountName) {
				return;
			}
			try {
				this.signature = ecc.sign(this.accountName, this.key);
				var result = await this.$parent.transactV2(this.getClaimAction());
				if (result) {
					if (result.status == "executed") {
						this.waxTxUrl = `${this.domainSettings.TxExplorerUrl}/${result.transaction.transaction_id}`;
						this.isSuccess = true;
						this.giftClaimed = true;
					}
				}
				this.showLoadingModal = false;
			} catch (e) {
				console.log(e);
				this.errorMessage = e.toString();
				this.showLoadingModal = false;
			}
		},

		getClaimAction() {
			let actions = [];
			actions.push({
				account: "atomictoolsx",
				name: "claimlink",
				authorization: [
					{
						actor: this.accountName,
						permission: this.permission,
					},
				],
				data: {
					link_id: this.linkID,
					claimer: this.accountName,
					claimer_signature: this.signature,
				},
			});
			return actions;
		},

		onGiftSuccess(response) {
			this.giftClaimed = true;
			this.isSuccess = response.data.isSuccess;
			this.waxTxUrl = `${this.domainSettings.TxExplorerUrl}/${response.data.transactionID}`;
			this.giftClaimDate = response.data.claimDate;
			this.errorMessage = response.data.errorMessage;
			this.showLoadingModal = false;
		},

		getAssetByID(linkID) {
			if (!linkID) {
				linkID = "";
			}
			let service = new GiftLinkService();
			service.getAssetByLinkID(linkID).then((response) => {
				if (response.data.isClaimed) {
					this.giftClaimed = true;
					this.isSuccess = true;
				}
				let data = response.data.asset;
				this.userGift = response.data.gift;
				this.assetImgUrl = data.imgUrl
					? data.imgUrl
					: "https://giftnft.s3.amazonaws.com/card-placeholder.jpg";
				this.assetName = data.name;
				this.assetTemplateMint = data.templateMint;
			});
		},
		closeConfirmModal() {
			this.showConfirmModal = false;
		},
		getExplorerUrl() {
			switch (this.domain) {
				case DOMAIN.WAX:
					if (this.assetType == ASSETTYPE.AA) {
						return `${this.domainSettings.ExplorerUrl}/profile/${this.accountName}#inventory`;
					} else {
						return `${this.simpleAssetInventory}main?searchString=${this.accountName}`;
					}
				case DOMAIN.GYB:
				case DOMAIN.MEME:
					return `${this.domainSettings.ExplorerUrl}/collections`;
				case DOMAIN.NEWDEX:
					return `${this.domainSettings.ExplorerUrl}/account`;
			}
		},
		getClaimBtnText() {
			if (this.accountName) {
				return "Claim";
			}
			switch (this.domain) {
				case DOMAIN.WAX:
					return "Login To Wax";
				case DOMAIN.GYB:
				case DOMAIN.MEME:
				case DOMAIN.NEWDEX:
					return "Login";
			}
		},
	},
	mounted() {
		this.linkID = this.$route.params.linkID;
		if (this.$route.query.key) {
			this.key = this.$route.query.key;
		}
		this.qrCampaignUID = this.$route.query.u;
		this.campaignSource = this.$route.query.source;
		//let service = new UserGiftService();
		console.log(this.key);
		if (this.linkID) {
			this.getAssetByID(this.linkID);
		}
	},
};
</script>

<style scoped lang="scss">
$padding: 60px;

.claim {
	position: relative;
	z-index: 30;

	@media screen and (min-width: $desktop) {
		margin-top: $padding;
		margin-bottom: $padding * 2;
	}

	@media screen and (max-width: $desktop) {
		padding: 40px 0;
	}

	@media screen and (max-width: 766px) {
		overflow-x: hidden;
		overflow-y: visible;
		padding: 40px 30px;
	}
}

.columns.is-full-height {
	min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$padding});
}

.login-required {
	position: relative;

	h1,
	p {
		margin-bottom: 30px !important;
		color: $white;
	}

	p {
		margin-bottom: 35px !important;
	}
}

.gift-preview {
	position: relative;
	margin-right: 30px;
	overflow: hidden;
	background: $white;
	border-radius: 5px;
	box-shadow: $box-shadow-large;

	@media screen and (max-width: 766px) {
		margin-right: 0;
	}

	@media screen and (min-width: $tablet) and (max-width: $desktop) {
		margin-left: 30px;
		margin-right: 30px;
	}

	.header {
		$size: 30px;
		padding: 10px 20px;
		border-bottom: 1px solid $border;

		img {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			height: $size;
			margin-top: 1px;
		}

		i {
			float: right;
			font-size: 21px;
			color: $primary;
			line-height: $size;
		}
	}

	.asset-preview {
		padding: 30px 50px;
		text-align: center;
		background: $light;
		overflow: hidden;

		img,
		video {
			display: inline-block;
			vertical-align: middle;
			-webkit-filter: drop-shadow(0 2px 3px rgba(black, 0.3));
			filter: drop-shadow(0 2px 3px rgba(black, 0.3));
			// box-shadow: 0 2px 3px rgba(black, 0.3);
		}

		.asset-name {
			margin-top: 15px;
			background: $light;
			line-height: 1;

			.num,
			.name,
			.column {
				display: block;
				font-weight: bold;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.num {
				padding-right: 10px;
				color: $text;
				text-align: left;
			}

			.name {
				padding-left: 10px;
				color: $primary;
				text-align: right;
			}
			.name-center {
				padding-left: 10px;
				color: $primary;
				text-align: center;
			}
		}
	}

	.msg-box {
		padding: 15px 20px;
		border-top: 1px solid $border;
		color: $info;
		text-align: center;
		//text-transform: uppercase;

		div {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.claim-nft {
	@media screen and (max-width: 766px) {
		margin-top: 30px;
	}

	h2 {
		margin-bottom: 25px;
		color: $link;
	}

	p {
		margin-bottom: 30px !important;
		color: $white;

		strong {
			color: $white;
		}
	}

	.label {
		margin-bottom: 5px;
		color: $white;
		line-height: 1;
	}

	button {
		display: inline-block;
		padding: 0px 30px;
		height: 46px;
		text-transform: uppercase;
		background: $gradient;
		background-repeat: no-repeat;
		font-weight: bold;
		font-size: $body-size;
		border: none;
		transition: all 0.2s linear;

		&:hover {
			border: none;
			background: $gradient;
			transform: translateY(1px);
		}
	}

	.sent-from {
		margin-bottom: 30px;

		p {
			margin: 0 !important;
			text-transform: uppercase;

			span {
				display: inline-block;
				width: 80px;
				font-weight: bold;
				color: $link;
			}
		}
	}
}
</style>