<template>
	<div>
		<div class="confirm-send modal" :class="{ 'is-active' : showLoginModal}">
			<div class="modal-background" @click="closeLoginModal()"></div>
			<div class="modal-card">
				<header class="modal-card-head is-radiusless">
					<p class="modal-card-title has-text-weight-bold">Select Login Method</p>
					<button @click="closeLoginModal()" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
                    <button v-if="showMetaMask" class="button is-primary is-rounded is-outlined has-text-weight-bold" @click="metamaskLogin()">
						<!-- <i class="fab fa-twitter"></i> -->
                        <img src="@/assets/img/metamask-logo-png-transparent.png" alt="bountyblok" style="height:25px;width:25px;margin-right:5px"/>
						Metamask
					</button>
                    <br><br>
					<div v-if="showArkane">
						<button class="button is-primary is-rounded is-outlined has-text-weight-bold" @click="arkaneLogin('google')">
							<i class="fab fa-google"></i>
							Google
						</button>
						<br><br>
						<button class="button is-primary is-rounded is-outlined has-text-weight-bold" @click="arkaneLogin('facebook')">
							<i class="fab fa-facebook"></i>
                            <!-- <img src="@/assets/img/facebook-logo.png" alt="bountyblok" style="height:25px;width:25px;margin-right:5px"/> -->
							Facebook
						</button>
						<br><br>
						<button class="button is-primary is-rounded is-outlined has-text-weight-bold" @click="arkaneLogin('twitter')">
							<i class="fab fa-twitter"></i>
                            <!-- <img src="@/assets/img/twitter-logo.png" alt="bountyblok" style="height:25px;width:25px;margin-right:5px"/> -->
							Twitter
						</button>
					</div>
				</section>
			</div>
		</div>
		<div class="confirm-send modal" :class="{ 'is-active' : showErrorModal}">
			<div class="modal-background" @click="closeErrorModal()"></div>
			<div class="modal-card">
				<header class="modal-card-head is-radiusless">
					<p class="modal-card-title has-text-weight-bold">Error</p>
					<button @click="closeErrorModal()" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body">
					<p class="has-text-weight-bold">{{errorMessage}} <span v-if="domain == DOMAIN.MEME || domain == DOMAIN.NEWDEX">Find out how <a target="_blank" :href="getMetamaskSetupUrl()">here.</a></span></p>
					<br>
					<button class="button is-primary is-rounded is-outlined has-text-weight-bold" @click="openLoginModal(view)">Try Again</button>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import { ArkaneConnect } from "@arkane-network/arkane-connect";
import detectEthereumProvider from '@metamask/detect-provider';
import { GYBPROVIDER } from '../core/models/constants';
import  AbiCoder  from 'web3-eth-abi';
import { DOMAIN } from '../core/models/constants'

export default {
    props:{
        domain:{
             type: Number,
             required: true
        },
        domainSettings:{
            type: Object
        }
    },
  name: "GybLogin",
   data() {
	return {
        //arkane
		arkaneConnect:null,
		walletID:null,
		showArkane:false,
        arkaneSecret:'BSC',

        //metamask
        metamaskEnabled:false,
        showMetaMask:true,
        metamaskSelectedChain:'0x4', 

		//shared
		showLoginModal:false,
		showErrorModal:false,
		errorMessage:null,
        ethAddress:null,
        view:null,
        provider:GYBPROVIDER.ARKANE,
        allowedChain:"",
        DOMAIN:DOMAIN,
	}
   },
  methods:{
      // ARKANE SECTION
		arkaneLogin(type){
            this.provider = GYBPROVIDER.ARKANE
			this.arkaneConnect.checkAuthenticated().then((re)=>{
                console.log('is auth', re)
                //if(re.isAuthenticated){
                   // this.getWallets()
               // }
                //else{
                    this.arkaneConnect.flows.authenticate({ windowMode: 'POPUP', idpHint:type }).then((result) => {
					result.authenticated((auth) => {
						console.log(auth)
						//this.arkaneConnect.flows.manageWallets('ETHEREUM').then((res) =>{
							//console.log(res)
							this.getWallets()
						//})
					})
					.notAuthenticated((auth) => {
						alert('not logged in',auth);
					});
				});
                //}
			})
		},

		getWallets(){
			this.arkaneConnect.api.getWallets({secretType:this.arkaneSecret}).then((result) => {
                if(result.length > 0){
                    this.ethAddress = result[0].address
                    this.walletID = result[0].id
                    this.onLoginSuccess()
                    }
                    else{
                        this.arkaneConnect.flows.getAccount(this.arkaneSecret).then((account) => {
                            this.ethAddress = account.wallets[0].address
                            this.walletID = account.wallets[0].id
                            this.onLoginSuccess()
                        });
                    }
				})
		},
        // ARKANE SECTION ENDS

        // METAMASK SECTION
        async startApp(metamaskProvider) {
			this.metamaskSelectedChain = window.ethereum.chainId
			if(!this.metamaskSelectedChain){
				this.metamaskSelectedChain = await window.ethereum.request({ method: 'eth_chainId' });
			}
			window.ethereum.on('chainChanged', this.handleChainChanged);
            // If the provider returned by detectEthereumProvider is not the same as
            // window.ethereum, something is overwriting it, perhaps another wallet.
            if (metamaskProvider !== window.ethereum) {
                console.log(metamaskProvider)
                console.error('Do you have multiple wallets installed?');
            }
            // Access the decentralized web!
        },
        metamaskLogin() {
            if(!this.metamaskEnabled){
                return;
            }
            console.log(this.metamaskSelectedChain)
            if(this.metamaskSelectedChain != this.allowedChain){
                if(this.domain == DOMAIN.GYB){
                    this.errorMessage = 'Please Select Rinkeby Test Network inside Metamask.'
                }
                else if(this.domain == DOMAIN.MEME ){
                    this.errorMessage = 'Please Select Polygon Mainnet inside Metamask.'
                }
                else if(this.domain == DOMAIN.NEWDEX){
                    this.errorMessage = 'Please Select Binance Smart Chain inside Metamask.'
                }
				
				this.showLoginModal = false
				this.showErrorModal = true
                return;
            }
            this.provider = GYBPROVIDER.METAMASK
            window.ethereum
            .request({ method: 'eth_requestAccounts' })
            .then(this.handleAccountsChanged)
            .catch((err) => {
                if (err.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    // If this happens, the user rejected the connection request.
                    console.log('Please connect to MetaMask.');
                } else {
					this.errorMessage = err.message
					this.showErrorModal = true
                    console.error(err);
                }
            });
        },
        handleAccountsChanged(accounts) {
            if (accounts.length === 0) {
                // MetaMask is locked or the user has not connected any accounts
                console.log('Please connect to MetaMask.');
            } else if (accounts[0] !== this.ethAddress ) {
                this.ethAddress = accounts[0];
                // Do any other work!
			}
			this.onLoginSuccess()
		},
		handleChainChanged() {
			// We recommend reloading the page, unless you must do otherwise
			this.logout();
			window.location.reload()
		},
        getMetamaskSetupUrl(){
            switch (this.domain) {
                case DOMAIN.MEME:
				case DOMAIN.GYB:
                    return `https://docs.matic.network/docs/develop/metamask/config-matic/`
                case DOMAIN.NEWDEX:
					return `https://docs.binance.org/smart-chain/wallet/metamask.html`
			}
        },

		// METAMASK SECTION ENDS
		openLoginModal(view, toGift){
			this.view = view
			this.errorMessage = null
			this.showErrorModal = false
			if (this.ethAddress) {
				this.$emit("update-accountname", this.ethAddress);
				if (view && this.$route.name != "Gift") {
					this.$router.push({
						path: this.view,
						query: this.$route.query,
					});
				}
			}
			else{
                
				if(this.domain == DOMAIN.MEME){
                    this.showArkane = false;
                }
                else{
                    if(toGift){
                        this.showArkane = false
                    }
                    else{
                        this.showArkane = this.$route.name == "Claim" ? true : false
                    }
                }
                //this.showMetaMask = this.$route.name == "Claim" ? false : true

                if(!this.showArkane){
                    this.metamaskLogin()
                }
                else{
                    this.showLoginModal = true
                }
				
			}
		},
		logout(){
            if(this.provider == GYBPROVIDER.ARKANE){
                this.arkaneConnect.checkAuthenticated().then((re)=>{
				if(re.isAuthenticated){
                    this.arkaneConnect.logout({ windowMode: 'POPUP' });
                    this.onLogoutSuccess()
                }
			})
            }
            else{
                this.onLogoutSuccess()
            }	
		},
		closeLoginModal(){
			this.showLoginModal = false
		},
		closeErrorModal(){
			this.showErrorModal = false
		},
        onLoginSuccess(){
            axios.post(`${process.env.VUE_APP_BASE_API_URL}members/authenticate`, {
                username: this.ethAddress}).then((response) => {
                    if (response.status == 200) {
                        this.closeLoginModal()
                        localStorage.setItem("userGuid", response.data.userGuid);
                        if(this.provider == GYBPROVIDER.ARKANE){
                            localStorage.setItem("arkane-wallet-id", this.walletID);
                        }
                        localStorage.setItem("gyb-provider", this.provider)
                        localStorage.setItem("ethereum-account", this.ethAddress)
                        this.$emit("update-accountname", this.ethAddress);
                        if(this.$route.query.returnUrl){
                            this.$router.push(this.$route.query.returnUrl);
                        }
                        else if (this.view) {
                            this.$router.push(this.view);
                        }
					}
                })
                .catch(function(error) {
                    console.log(error);
                });
        },
        onLogoutSuccess(){
            this.ethAddress = "";
            this.$emit("update-accountname", this.ethAddress);
            localStorage.removeItem("userGuid");
            localStorage.removeItem("gyb-provider");
            localStorage.removeItem('ethereum-account')
            if(this.provider == GYBPROVIDER.ARKANE){
                localStorage.removeItem("arkane-wallet-id");
            }
            if(this.$route.name == 'Gift'){
                this.$router.push({
                    path: `/`,
                    query: {id : this.$route.query.id},
                })
            }
        },
        async transferNFT(data){
            if(this.provider == GYBPROVIDER.ARKANE){
                const signer  =  this.arkaneConnect.createSigner();
                let result = await signer.executeNftTransfer({
                    walletId: this.walletID,
                    to: data.toAddress,
                    tokenAddress: data.tokenAddress,
                    tokenId: data.tokenID,
                    secretType: this.arkaneSecret,
                })
                console.log(result)
                if(result){
                    return result.result.transactionHash
                }
            }
            else{
                let encodedTransactionParams = AbiCoder.encodeFunctionCall({
                    name: 'safeTransferFrom',
                    type: 'function',
                    inputs: [{
                        type: 'address',
                        name: '_from'
                    },
                    {
                        type: 'address',
                        name: '_to'
                    },
                    {
                        type: 'uint256',
                        name: '_id'
                    },
                    // {
                    //     type: 'uint256',
                    //     name: '_value'
                    // },
                    // {
                    //     type: 'bytes',
                    //     name: '_data'
                    // },
                ]},
                [this.ethAddress, data.toAddress,data.tokenID]);
                
                const transactionParameters = {
                to: data.tokenAddress, // Required except during contract publications.
                from: this.ethAddress, // must match user's active address.
                data: encodedTransactionParams
                };
                const txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
                });
                console.log("tx hash",txHash)
                return txHash;
            }
        }
		},
		async mounted() {    
            this.allowedChain = this.domainSettings.ChainID 
            const metamaskProvider = await detectEthereumProvider();
            if (metamaskProvider) {
                // From now on, this should always be true:
                // provider === window.ethereum
                this.metamaskEnabled = true;
				await this.startApp(metamaskProvider); // initialize your ap
				
                } else {
                    this.metamaskEnabled = false;
                    console.log('Please install MetaMask!');
                }

            this.arkaneConnect = new ArkaneConnect('bountyblok', {environment: 'staging', windowMode: 'POPUP'}); // for staging , rinkeby network etc
            //this.arkaneConnect = new ArkaneConnect('bountyblok', {windowMode: 'POPUP'});
            const existingProvider = localStorage.getItem("gyb-provider")
            if(existingProvider){
                this.provider = existingProvider
                if(this.provider == GYBPROVIDER.ARKANE){
                    this.arkaneConnect.checkAuthenticated().then((re)=>{
                    if(re.isAuthenticated){
                        this.getWallets()
                    }
                })
                }
                else{
                    const ethAccount = localStorage.getItem('ethereum-account')
                    if(ethAccount){
                        this.ethAddress = ethAccount
                        this.onLoginSuccess()
                    }
                }

            }

        },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-card {
			p {
				color: #000000;
			}
		}
</style>
