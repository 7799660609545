export default class MassEmailModel {
    constructor() {
        this.FromName = ""
        this.CollectionName = ""
        this.TemplateID = ""
        this.Message = ""
        this.UserGuid = ""
        this.EmailAddresses = ""
        this.UserGuid = ""
    }
}