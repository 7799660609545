import axios from "axios";

const API_URL = `${process.env.VUE_APP_BASE_API_URL}usergift`;
const API_ENDPOINT_CREATEGIFT = '/create'
const API_ENDPOINT_CLAIMGIFT = '/claim'
const API_ENDPOINT_GETGIFTCLIENT = '/giftclient'
const API_ENDPOINT_GETUSERGIFT = '/usergift'
const API_ENDPOINT_GETUSERGIFTS = '/mygifts'
const API_ENDPOINT_GETUSERCLAIMS = '/myclaims'
const API_ENDPOINT_RESENDGIFT = '/resendgift'
const API_ENDPOINT_GETASSETS = '/getassets'
const API_ENDPOINT_GETARKANEASSETS = '/getarkaneassets'
const API_ENDPOINT_GETBSCASSETS = '/getbscassets'
const API_ENDPOINT_GETHEDERAASSETS = '/gethederaassets'
const API_ENDPOINT_GETASSETBYID = '/getassetbyid'
const API_ENDPOINT_GETTEMPLATEBYID = '/gettemplatebyid'
const API_ENDPOINT_CLAIMQRCAMPAIGN = '/claimqrcampaign'
const API_ENDPOINT_SENDMASSEMAIL = '/sendemail'
const API_ENDPOINT_GETCOLLECTIONS = '/getcollections'
const API_ENDPOINT_GETTEMPLATES = '/gettemplates'
const API_ENDPOINT_ASSOCIATETOKEN = '/associatetoken'
const API_ENDPOINT_HASTOKENASSOCIATION = '/hastokenassociation'
const API_ENDPOINT_CANCELGIFT = '/cancelgift'
const API_ENDPOINT_VERIFYCLOUDFLARE = '/verifycloudflare'

export default class UserGiftService {
    constructor() {

    }

    createUserGift(model) {
        const url = API_URL + API_ENDPOINT_CREATEGIFT
        return axios
            .post(url, model)

    }

    claimGift(model) {
        const url = API_URL + API_ENDPOINT_CLAIMGIFT
        return axios
            .post(url, model)
    }

    getGiftClient(id) {
        const url = `${API_URL}${API_ENDPOINT_GETGIFTCLIENT}?id=${id}`;
        return axios.get(url)
    }
    getUserGift(id) {
        const url = `${API_URL}${API_ENDPOINT_GETUSERGIFT}?id=${id}`;
        return axios.get(url)
    }
    getUserGifts(pageNo,pageSize){
        const url = `${API_URL}${API_ENDPOINT_GETUSERGIFTS}?userID=${encodeURIComponent(localStorage.getItem("userGuid"))}&pageNo=${pageNo}&pageSize=${pageSize}`;
        return axios.get(url)
    }
    getUserClaims(pageNo,pageSize){
        const url = `${API_URL}${API_ENDPOINT_GETUSERCLAIMS}?userID=${encodeURIComponent(localStorage.getItem("userGuid"))}&pageNo=${pageNo}&pageSize=${pageSize}`;
        return axios.get(url)
    }

    resendGift(model) {
        const url = API_URL + API_ENDPOINT_RESENDGIFT
        return axios
            .post(url, model)

    }

    getAssets(waxAccountName){
        const url = `${API_URL}${API_ENDPOINT_GETASSETS}?waxAccountName=${waxAccountName}`;
        return axios.get(url)
    }
    getArkaneAssets(ethAddress){
        const url = `${API_URL}${API_ENDPOINT_GETARKANEASSETS}?ethAddress=${ethAddress}`;
        return axios.get(url)
    }
    getBSCAssets(address){
        const url = `${API_URL}${API_ENDPOINT_GETBSCASSETS}?address=${address}`;
        return axios.get(url)
    }
    getHederaAssets(account){
        const url = `${API_URL}${API_ENDPOINT_GETHEDERAASSETS}?account=${account}`;
        return axios.get(url)
    }
    getAssetByID(assetID,templateID,collection,assetType){
        const url = `${API_URL}${API_ENDPOINT_GETASSETBYID}?assetID=${assetID}&templateID=${templateID}&assetType=${assetType}&collection=${collection}`;
        return axios.get(url)
    }
    getTemplateByID(templateID,startDate,endDate,maxCount,uid){
        let url = `${API_URL}${API_ENDPOINT_GETTEMPLATEBYID}?templateID=${templateID}`
        if(startDate){
            url = `${url}&startDate=${startDate}`
        }
        if(endDate){
            url = `${url}&endDate=${endDate}`
        }
        if(maxCount){
            url = `${url}&maxCount=${maxCount}`
        }
        if(uid){
            url = `${url}&uid=${uid}`
        }
        return axios.get(url)
    }

    claimQRCampaignGift(model) {
        const url = API_URL + API_ENDPOINT_CLAIMQRCAMPAIGN
        return axios
            .post(url, model)
    }

    sendMassEmail(model) {
        model.UserID = localStorage.getItem("userGuid")
        const url = API_URL + API_ENDPOINT_SENDMASSEMAIL
        return axios
            .post(url, model)
    }

    getCollections(waxAccountName) {
        const url = `${API_URL}${API_ENDPOINT_GETCOLLECTIONS}?waxAccountName=${waxAccountName}`;
        return axios.get(url)
    }

    getTemplates(collectionName) {
        const url = `${API_URL}${API_ENDPOINT_GETTEMPLATES}?collectionName=${collectionName}`;
        return axios.get(url)
    }

    associateToken(tokenID) {
        const url = `${API_URL}${API_ENDPOINT_ASSOCIATETOKEN}?tokenID=${tokenID}`
        return axios
            .post(url)
    }

    hasTokenAssociation(tokenID,account) {
        const url = `${API_URL}${API_ENDPOINT_HASTOKENASSOCIATION}?tokenID=${tokenID}&account=${account}`;
        return axios.get(url)
    }
    cancelGift(giftID,txID) {
        const model = {
            GiftID:giftID,
            TxID:txID,
            UserID:localStorage.getItem("userGuid")
        }
        const url = `${API_URL}${API_ENDPOINT_CANCELGIFT}`;
        return axios
        .post(url,model)
    }

    verifyCloudflare(code) {
        const url = `${API_URL}${API_ENDPOINT_VERIFYCLOUDFLARE}?code=${code}`
        return axios
            .post(url)
    }
}