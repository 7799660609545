<template>
	<div class="my-gifts">
		<div class="container is-max-desktop">
			<div class="columns is-full-height is-centered is-gapless is-desktop">
				<div class="column is-12-tablet is-11-desktop">
					<div v-if="resendError" class="notification is-danger">
						<p>{{ resendError }}</p>
					</div>
					<!-- Confirmation Modal Starts-->
					<div
						class="confirm-send modal"
						:class="{ 'is-active': showResendModal }"
					>
						<div class="modal-background" @click="closeConfirmModal()"></div>
						<div class="modal-card">
							<header class="modal-card-head">
								<p class="modal-card-title has-text-weight-bold">
									Resend Email?
								</p>
								<button
									@click="closeConfirmModal()"
									class="delete"
									aria-label="close"
								></button>
							</header>
							<section class="modal-card-body">
								<div class="field is-horizontal">
									<!-- <div class="field-label is-normal">
									<label class="label">To</label>
								</div> -->
									<div class="field-body">
										<div class="field">
											<ValidationProvider
												name="email"
												ref="emailRef"
												rules="required|email|max:64"
												v-slot="{ errors, classes }"
											>
												<p class="control">
													<input
														v-model="selectedEmail"
														class="input"
														:class="classes"
														type="text"
														placeholder="Recepient email"
													/>
												</p>
												<span class="help is-danger">
													{{ errors[0] }}
												</span>
											</ValidationProvider>
										</div>
									</div>
								</div>
							</section>
							<footer class="modal-card-foot">
								<button class="button is-primary" @click="cancelGift()">
									Confirm
								</button>
								<button
									@click="closeConfirmModal()"
									class="button is-text is-outlined"
								>
									Cancel
								</button>
							</footer>
						</div>
					</div>
					<!-- Confirmation Modal Ends-->

					<!-- Twitter Resend Modal Starts-->
					<div
						class="confirm-send modal"
						:class="{ 'is-active': showTwitterResendModal }"
					>
						<div
							class="modal-background"
							@click="closeTwitterResendModal()"
						></div>
						<div class="modal-card">
							<header class="modal-card-head">
								<p class="modal-card-title has-text-weight-bold">
									Resend Email?
								</p>
								<button
									@click="closeTwitterResendModal()"
									class="delete"
									aria-label="close"
								></button>
							</header>
							<section class="modal-card-body">
								<div class="field is-horizontal">
									<!-- <div class="field-label is-normal">
									<label class="label">To</label>
								</div> -->
									<div class="field-body">
										<div class="field">
											<ValidationProvider
												name="totwitter"
												ref="twitterRef"
												rules="required|max:64"
												v-slot="{ errors, classes }"
											>
												<p class="control">
													<input
														v-model="selectedEmail"
														class="input"
														:class="classes"
														type="text"
														placeholder="Enter a Twitter follower"
													/>
												</p>
												<span class="help is-danger">
													{{ errors[0] }}
												</span>
											</ValidationProvider>
										</div>
									</div>
								</div>
							</section>
							<footer class="modal-card-foot">
								<button class="button is-primary" @click="sendEmail()">
									Send
								</button>
								<button
									@click="closeTwitterResendModal()"
									class="button is-text is-outlined"
								>
									Cancel
								</button>
							</footer>
						</div>
					</div>
					<!-- Twitter Resend Modal Ends-->

					<!-- Cancel Modal Starts-->
					<div
						class="confirm-send modal"
						:class="{ 'is-active': showCancelModal }"
					>
						<div class="modal-background" @click="closeCancelModal()"></div>
						<div class="modal-card">
							<header class="modal-card-head">
								<p class="modal-card-title has-text-weight-bold">
									Cancel Gift?
								</p>
								<button
									@click="closeCancelModal()"
									class="delete"
									aria-label="close"
								></button>
							</header>
							<section class="modal-card-body">
								<div>
									Are you sure you want to cancel this gift? Your NFT will be
									returned to your wallet.
								</div>
							</section>
							<footer class="modal-card-foot">
								<button class="button is-primary" @click="cancelGift()">
									Send
								</button>
								<button
									@click="closeCancelModal()"
									class="button is-text is-outlined"
								>
									Cancel
								</button>
							</footer>
						</div>
					</div>
					<!-- Cancel Modal Ends-->

					<div class="content">
						<h2>My Gifts</h2>
						<div class="table-container">
							<table class="table is-bordered is-fullwidth">
								<thead>
									<tr>
										<th>To</th>
										<th>Collection</th>
										<th>NFT (Asset ID)</th>
										<th>Date</th>
										<th>Claimed</th>
										<th>TX</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="gift in mygifts" :key="gift.AssetID">
										<td>
											<a
												class="has-text-white"
												target="_blank"
												v-if="gift.deliveryBy == DELIVERYTYPE.TWITTERDM"
												:href="`https://twitter.com/${gift.toEmail}`"
												>{{ gift.toEmail }}</a
											>
											<span v-else>{{
												gift.toEmail ? gift.toEmail : gift.toPhone
											}}</span>
											<br />
											<!--<a class="resend-email" v-if="gift.resendEmail" @click="openResendModal(gift),selectedEmail=gift.toEmail,selectedGift=gift">{{getResendText(gift)}}</a>-->
											<a
												class="resend-email"
												v-if="gift.resendEmail"
												@click="
													openCancelModal(gift),
														(selectedEmail = gift.toEmail),
														(selectedGift = gift)
												"
												>Cancel</a
											>
											<span
												class="sent-message"
												v-if="
													!gift.isClaimed && gift.expiresIn && !gift.resendEmail
												"
												>CANCELLED!</span
											>
										</td>
										<td>
											<a
												target="_blank"
												:href="
													getCollectionExplorerUrl(
														gift.collectionName,
														gift.templateID
													)
												"
											>
												{{ gift.collectionName }}
											</a>
										</td>
										<td>
											<a
												target="_blank"
												:href="
													getAssetExplorerUrl(gift.assetID, gift.templateID)
												"
											>
												{{ gift.assetID | truncate(10, "...") }}
											</a>
										</td>
										<td>
											{{ gift.createDate | moment("MMM Do, YYYY, h:mm:ss a") }}
											<!-- <span v-if="!gift.isClaimed && gift.expiresIn">
												<br/>
												Expires in: {{ gift.expiresIn }}
											</span> -->
										</td>
										<td>
											{{
												gift.isClaimed
													? "Yes"
													: !gift.expiresIn
													? "EXPIRED"
													: "No"
											}}
										</td>
										<td>
											<a
												target="_blank"
												:href="`${domainSettings.TxExplorerUrl}/${gift.transactionID}`"
											>
												{{ gift.transactionID | truncate(6, "") }}
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<nav
							v-if="showPrevious || showNext"
							class="pagination is-rounded"
							role="navigation"
							aria-label="pagination"
						>
							<a
								v-if="showPrevious"
								class="pagination-previous is-uppercase has-text-weight-bold"
								@click="fetchGifts('previous')"
								>Previous</a
							>
							<a
								v-if="showNext"
								class="pagination-next is-uppercase has-text-weight-bold"
								@click="fetchGifts('next')"
								>Next page</a
							>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import UserGiftService from "../core/services/user-gift.service";
import ResendGiftModel from "../core/models/resend-gift.model";
import { DOMAIN, DELIVERYTYPE } from "../core/models/constants";
export default {
	props: {
		accountName: {
			type: String,
			required: true,
		},
		domain: {
			type: Number,
			required: true,
		},
		domainSettings: {
			type: Object,
		},
	},
	data() {
		return {
			pageSize: 10,
			pageNo: 0,
			mygifts: [],
			showNext: true,
			showPrevious: true,
			atomichubUrl: process.env.VUE_APP_ATOMICHUB_URL,
			waxUrl: process.env.VUE_APP_WAX_URL,
			showResendModal: false,
			selectedEmail: "",
			selectedGift: null,
			theme: null,
			showTwitterResendModal: false,
			twitterConnected: false,
			followers: [],
			selectedFollower: null,
			DELIVERYTYPE: DELIVERYTYPE,
			resendError: null,
			showCancelModal: false,
		};
	},
	methods: {
		getToText(gift) {
			if (gift.deliveryBy == DELIVERYTYPE.EMAIL) {
				return gift.toEmail;
			} else if (gift.deliveryBy == DELIVERYTYPE.TWITTERDM) {
				return <a href="https://twitter.com/{{gift.toEmail}}">text</a>;
			} else {
				return gift.toPhone;
			}
		},
		getResendText(gift) {
			return gift.deliveryBy == DELIVERYTYPE.EMAIL
				? "Resend Email"
				: "Resend DM";
		},
		openResendModal(gift) {
			if (gift.deliveryBy == DELIVERYTYPE.EMAIL) {
				this.showResendModal = true;
			} else {
				this.showTwitterResendModal = true;
			}
		},
		openCancelModal() {
			this.showCancelModal = true;
		},
		fetchGifts(direction) {
			if (direction == "previous") {
				if (!this.showPrevious) {
					return;
				}
				this.pageNo = +this.pageNo - 1;
			} else if (direction == "next") {
				if (!this.showNext) {
					return;
				}
				this.pageNo = +this.pageNo + 1;
			}

			let service = new UserGiftService();
			service
				.getUserGifts(this.pageNo, this.pageSize)
				.then((res) => {
					if (res.status == 200) {
						this.mygifts = res.data.gifts;
						this.showNext = res.data.showNext;
						this.showPrevious = res.data.showPrevious;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		closeConfirmModal() {
			this.showResendModal = false;
		},
		closeTwitterResendModal() {
			this.showTwitterResendModal = false;
		},
		closeCancelModal() {
			this.showCancelModal = false;
		},
		async sendEmail() {
			//validate email field
			this.resendError = null;
			let provider = null;
			if (this.selectedGift.deliveryBy == DELIVERYTYPE.EMAIL) {
				provider = this.$refs["emailRef"];
			} else {
				provider = this.$refs["twitterRef"];
			}
			const validationResult = await provider.validate();
			if (!validationResult.valid) {
				return false;
			}

			this.closeConfirmModal();
			this.closeTwitterResendModal();

			let model = new ResendGiftModel();
			model.GiftClientID = this.$route.query.id;
			model.GiftID = this.selectedGift.giftID;
			model.DeliveryBy = this.selectedGift.deliveryBy;
			model.Email = this.selectedEmail;

			let service = new UserGiftService();
			service.resendGift(model).then((response) => {
				if (response.status == 200) {
					if (response.data.isSuccess) {
						this.selectedGift.resendEmail = false;
						this.selectedGift.toEmail = this.selectedEmail;
					} else {
						this.resendError = response.data.errorMessage;
					}
				}
			});
		},
		async cancelGift() {
			const cancelData = {
				linkID: this.selectedGift.linkID,
			};
			let tx = await this.$parent.cancelGift(cancelData);
			console.log(tx);
			if (tx) {
				let service = new UserGiftService();
			service.cancelGift(this.selectedGift.giftID,tx).then((response) => {
				if (response.status == 200) {
					if (response.data.isSuccess) {
						this.selectedGift.resendEmail = false;
						this.selectedGift.toEmail = this.selectedEmail;
					} else {
						this.resendError = response.data.errorMessage;
					}
				}
			});
				this.showCancelModal = false;
			}
		},
		getCollectionExplorerUrl(collectionName, templateID) {
			let url = this.domainSettings.ExplorerUrl;
			switch (this.domain) {
				case DOMAIN.WAX:
					url = `${url}/collection/${collectionName}`;
					break;
				case DOMAIN.GYB:
				case DOMAIN.MEME:
					url = `${url}/collection/${collectionName}`;
					break;
				case DOMAIN.NEWDEX:
					url = `${url}/assets?contract=${templateID}`;
					break;
			}
			return url;
		},
		getAssetExplorerUrl(assetID, templateID) {
			let url = this.domainSettings.ExplorerUrl;
			switch (this.domain) {
				case DOMAIN.WAX:
					url = `${url}/asset/${assetID}`;
					break;
				case DOMAIN.GYB:
				case DOMAIN.MEME:
				case DOMAIN.NEWDEX:
					url = `${url}/assets/${templateID}/${assetID}`;
			}
			return url;
		},
		getFollowers() {
			axios
				.get(
					`${
						process.env.VUE_APP_BASE_API_URL
					}members/twitterfollowers?userID=${encodeURIComponent(
						localStorage.getItem("userGuid")
					)}`
				)
				.then((res) => {
					if (res.data) {
						this.followers = res.data;
					}
				});
		},
		
	},
	mounted() {
		// if not logged in
		// if(!localStorage.getItem('userGuid')){
		// 	this.$router.push(`/?id=${this.$route.query.id}`)
		// }
		this.fetchGifts(null);
		//check if user has twitter authenticated already
		// const twitterConnectedToken = localStorage.getItem('isTwitterConnected')
		// if(twitterConnectedToken){
		// 	if(twitterConnectedToken === "true"){
		// 		this.twitterConnected = true
		// 		this.getFollowers();
		// 	}
		// }
	},
};
</script>

<style scoped lang="scss">
$padding: 60px;

.my-gifts {
	position: relative;
	z-index: 30;

	@media screen and (min-width: $desktop) {
		margin-top: $padding;
		margin-bottom: $padding * 2;
	}

	@media screen and (max-width: $desktop) {
		overflow-x: hidden;
		overflow-y: visible;
		padding: 40px 30px;
	}

	h2 {
		margin-bottom: 25px;
		color: $link;
	}

	.columns.is-full-height {
		min-height: calc(
			100vh - #{$navbar-height} - #{$footer-height} - #{$padding}
		);

		@media screen and (max-width: 766px) {
			min-height: calc(100vh - #{$navbar-height} - 224px - 80px);
		}
	}

	table.table {
		thead tr th {
			text-align: left;
		}

		@media screen and (max-width: 766px) {
			tr td,
			tr th {
				white-space: nowrap;
			}
		}
	}

	.pagination {
		.pagination-previous,
		.pagination-next {
			text-align: center;

			@media screen and (max-width: 766px) {
				margin-left: 0;
				margin-right: 10px;
			}

			&:hover {
				background: $link;
			}
		}

		.pagination-next {
			margin-left: auto;

			@media screen and (max-width: 766px) {
				margin-left: 10px;
				margin-right: 0;
			}
		}
	}
}
.confirm-send.modal {
	.button.is-text {
		color: $danger;

		&:hover {
			background: inherit;
		}
	}
}
.resend-email {
	font-size: 12px;
}
.sent-message {
	font-weight: bold;
	color: #15bd51;
	font-size: 12px;
}
</style>
