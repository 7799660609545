<template>
	<div>
		<div class="confirm-send modal" :class="{ 'is-active': showHashpackModal }">
			<div class="modal-background" @click="closeHashpackModal()"></div>
			<div class="modal-card">
				<header class="modal-card-head is-radiusless">
					<p class="modal-card-title has-text-weight-bold">
						Login with HashPack
					</p>
					<button @click="closeHashpackModal()" class="delete" aria-label="close"></button>
				</header>
				<section class="modal-card-body content">
					<div class="tabs">
						<ul>
							<li @click="selectedTab = 1" :class="{ 'is-active': selectedTab == 1 }">
								<a>Chrome</a>
							</li>
							<li @click="selectedTab = 2" :class="{ 'is-active': selectedTab == 2 }">
								<a>Browser</a>
							</li>
						</ul>
					</div>
					<div v-if="selectedTab == 1">
						<ol type="1">
							<li>
								<strong>Step 1</strong>
								<p class="mb-0">Open the HashPack Chrome extension and unlock it</p>
							</li>
							<li>
								<strong>Step 2</strong>
								<p class="mb-0">Click "HashPack Chrome Login"</p>
							</li>
							<li>
								<strong>Step 3</strong>
								<p class="mb-0">Select the wallet you want to connect with and click "Approve"</p>
							</li>
						</ol>
						<div class="field">
							<button @click="chromeLogin()"
								class="button is-link is-rounded is-outlined is-uppercase has-text-weight-bold">
								<span>HashPack Chrome Login</span>
							</button>
						</div>
					</div>
					<div v-if="selectedTab == 2">
						<ol type="1">
							<li>
								<strong>Step 1</strong>
								<p class="mb-0">Copy this code</p>
								<div class="row pl-0">
									<div class="columns">
										<div class="column is-9">
											<input class="input" type="text" readonly :value="pairingString" />
										</div>
										<div class="column is-3">
											<div class="field">
												<button class="button is-link is-rounded is-outlined is-uppercase has-text-weight-bold" @click="copyCode">
													Copy
												</button>
											</div>
										</div>
									</div>
								</div>
							</li>
							<li class="mb-5">
								<strong>Step 2</strong>
								<p class="mb-0">Navigate to Hashpack and click the globe icon at the top. Afterwards click "Connect DApp"</p>
							</li>
							<li class="mb-5">
								<strong>Step 3</strong>
								<p class="mb-0">Paste in the copied code in the field that says "Pairing String"</p>
							</li>
							<li class="mb-5">
								<strong>Step 4</strong>
								<p class="mb-0">Select the wallet you want to connect with and hit "Approve"</p>
							</li>
						</ol>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
// Import the toast function
import { toast } from 'bulma-toast'

export default {
	props: {
		pairingString: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			selectedTab: 1,
			showHashpackModal: false,
		};
	},
	methods: {
		openHashpackModal() {
			this.showHashpackModal = true;
		},
		closeHashpackModal() {
			this.showHashpackModal = false;
		},
		chromeLogin() {
			this.$emit("hashpack-chrome-login");
			this.showHashpackModal = false;
		},
		async copyCode() {
			try {
				await navigator.clipboard.writeText(this.pairingString);
				toast({
					message: "Copied",
					type: "is-primary",
					//animate: { in: "fadeIn", out: "fadeOut" },
					duration: 2000,
					closeOnClick: true,
					position: 'top-center',
					dismissible:true
				});
			} catch ($e) {
				alert("Cannot copy");
			}
		},
	},
	mounted() {
		
	},
};
</script>
<style scoped lang="scss">
.modal-card {
	border-radius: $radius;

	@media screen and (min-width: $tablet) {
		width: 450px;
	}

	.modal-card-body {
		padding: 10px 20px 20px;
	}

	.tabs {
		background: $white;

		ul {
			margin: 0;

			li {

				a {
					color: $text;
				}

				&.is-active a {
					border-bottom: 1px solid $primary;
					color: $primary;
					font-weight: bold;
				}
			}
		}
	}

	ol {
		margin-left: 15px;
		margin-bottom: 20px!important;

		li {
			margin: 0;

			+ li {
				margin-top: 20px;
			}

			strong {
				color: $text;
			}
		}
	}
}
</style>