<template>
	<div class="batch-details">
		<div class="is-divider mt-0"></div>
		<div class="columns is-mobile is-centered">
			<div class="column is-half has-text-centered">
				<h2>Batches</h2>
			</div>
		</div>
		<div class="columns" v-for="batch in batchData" :key="batch.id">
			<div class="column">
				<p class="has-text-weight-bold">Batch {{ batch.id }}</p>
				<div class="mb-4">
					<p class="has-text">Total Accounts: {{ batch.totalAccounts }}</p>
				</div>
				<span class="has-text" v-if="batch.linksCreated && batch.txID">
					<img
						class="bloks-logo"
						src="@/assets/img/bloks_logomark.png"
						alt="bloks.io"
					/>
					<a
						class="tx-font"
						target="_blank"
						:href="`https://waxblock.io/transaction/${batch.txID}`"
						>{{ batch.txID }}</a
					>
				</span>
				<br />
				<span
					v-if="batch.assetsTransfered && batch.transferTxID"
					class="has-text"
				>
					<img
						class="bloks-logo"
						src="@/assets/img/bloks_logomark.png"
						alt="bloks.io"
					/>
					<a
						class="tx-font"
						target="_blank"
						:href="`https://waxblock.io/transaction/${batch.transferTxID}`"
						>{{ batch.transferTxID }}</a
					>
				</span>
				<div v-if="batch.error" class="notification is-danger">
					<span>{{ batch.error }}</span>
				</div>
			</div>
			<div class="column is-one-quarter has-text-right">
				<div class="field">
					<div class="control">
						<button
							:disabled="batch.sending"
							:class="{ 'is-loading': batch.sending }"
							v-if="!batch.sent && !batch.showResend && !batch.linksCreated"
							class="batch-btn button is-medium is-rounded is-link is-small"
							@click="send(batch)"
						>
							<i class="fas fa-paper-plane"></i> Send
						</button>
						<button
							:disabled="batch.sending"
							:class="{ 'is-loading': batch.sending }"
							v-if="batch.showResend && !batch.linksCreated"
							class="batch-btn button is-medium is-rounded is-link is-small"
							@click="send(batch)"
						>
							Retry
						</button>
						<button
							:disabled="batch.sending"
							:class="{ 'is-loading': batch.sending }"
							v-if="!batch.emailSent && batch.linksCreated"
							class="batch-btn button is-medium is-rounded is-link"
							@click="resendEmail(batch)"
						>
							Retry
						</button>
						<!-- <button
							:disabled="batch.sending"
							v-if="
								!batch.transferBatchSent &&
								!batch.showTransferResend &&
								!batch.sending &&
								batch.emailSent
							"
							class="batch-btn button is-medium is-rounded is-link"
							@click="transfer(batch)"
						>
							Transfer
						</button>
						<button
							:disabled="batch.sending"
							v-if="batch.showTransferResend && !batch.isSuccess"
							class="batch-btn button is-medium is-rounded is-link"
							@click="send(batch)"
						>
							ReTransfer
						</button> -->
					</div>
				</div>
			</div>
		</div>

		<!-- Loading Modal Starts-->
		<div
			class="confirm-loading modal"
			:class="{ 'is-active': showLoadingModal }"
		>
			<div class="modal-background" @click="closeLoadingModal"></div>
			<div class="modal-card">
				<section class="modal-card-body">
					<div class="has-text-centered">
						<div class="loading"></div>
						<h1>Sending Emails.</h1>
						<p>Please confirm the transaction in your wallet.</p>
					</div>
				</section>
			</div>
		</div>
		<!-- Loading Modal Ends-->
	</div>
</template>

<script>
import { ACTIONTYPE } from "../core/models/constants";
import BatchModel from "../core/models/batch.model";
import GiftLinkService from "../core/services/gift-link.service";

export default {
	props: {
		accountName: {
			type: String,
			required: true,
		},
		domain: {
			type: Number,
			required: true,
		},
		domainSettings: {
			type: Object,
		},
		emailAddresses: {
			type: String,
			required: true,
		},
		assets: {
			type: String,
			required: true,
		},
		batchSize: {
			type: String,
			required: true,
		},
		permission: {
			type: String,
			required: true,
		},
	},
	watch: {
		assets: async function () {
			if (this.assets) {
				//await this.buildBatches();
			}
		},
		emailAddresses: async function () {
			if (this.assets) {
				//await this.buildBatches();
			}
		},
	},
	data() {
		return {
			batchData: [],
			showLoadingModal: false,
		};
	},
	methods: {
		async send(batch) {
			batch.sending = true;
			batch.error = "";
			this.setResendBtnInterval(batch);
			try {
				// clearTimeout(batch.resendInterval);
				// batch.txID =
				// 	"8648b0d92cf1452a869abdd0bdbc812e6359c285877d4eb4e67b74b2c4491d6d";
				// batch.linksCreated = true;

				// // now send emails
				// const emailBlastResult = await this.$parent.$parent.emailBlast(
				// 	batch.accounts.toString(),
				// 	batch.keys,
				// 	batch.txID
				// );
				// console.log(emailBlastResult);
				// if (emailBlastResult.data.isSuccess) {
				// 	batch.emailSent = true;
				// } else {
				// 	batch.sent = true;
				// 	batch.sending = false;
				// 	batch.error =emailBlastResult.data.errorMessage
				// }
				// batch.sending = false;
				// batch.sent = true;

				var result = await this.$parent.$parent.$parent.transactV2(
					batch.actions
				);
				batch.sending = false;
				if (result) {
					if (result.status == "executed") {
						clearTimeout(batch.resendInterval);
						this.$emit("show-batch-btn", true);
						batch.txID = result.transaction.transaction_id;
						batch.sent = true;
						batch.linksCreated = true;

						// now send emails
						this.showLoadingModal = true;
						const emailBlastResult = await this.$parent.$parent.emailBlast(
							batch.accounts.toString(),
							batch.keys,
							batch.txID
						);
						console.log(emailBlastResult);
						if (emailBlastResult.data.isSuccess) {
							batch.emailSent = true;
							batch.isSuccess = true;
							this.updateCSV();
						} else {
							batch.sent = true;
							batch.sending = false;
							batch.error = emailBlastResult.data.errorMessage;
						}
						this.showLoadingModal = false;
					}
				}
			} catch (e) {
				console.log(e);
				batch.sent = true;
				batch.sending = false;
				batch.error = e.toString();
				this.showLoadingModal = false;
			}
		},
		async resendEmail(batch) {
			batch.error = "";
			batch.sending = true;
			this.showLoadingModal = true;
			// setTimeout(() => {
			// 	// now send emails
			// 	const emailBlastResult = true;
			// 	if (emailBlastResult) {
			// 		batch.emailSent = true;
			// 	} else {
			// 		batch.emailSent = false;
			// 		batch.error = "Failed to send";
			// 	}
			// 	batch.sending = false;
			// 	//this.showLoadingModal = false
			// }, 2000);
			try {
				const emailBlastResult = await this.$parent.$parent.emailBlast(
					batch.accounts.toString(),
					batch.keys,
					batch.txID
				);
				console.log(emailBlastResult);
				if (emailBlastResult.data.isSuccess) {
					batch.emailSent = true;
					batch.isSuccess = true;
					this.updateCSV();
				} else {
					batch.emailSent = false;
					batch.error = emailBlastResult.data.errorMessage;
				}
				batch.sending = false;
				this.showLoadingModal = false;
			} catch (e) {
				console.log(e);
				batch.sent = true;
				batch.sending = false;
				this.showLoadingModal = false;
				batch.error = e.toString();
			}
		},
		async transferfake(batch) {
			batch.error = "";
			batch.sending = true;
			this.setTransferResendBtnInterval(batch);
			try {
				batch.sending = false;
				batch.transferTxID =
					"8648b0d92cf1452a869abdd0bdbc812e6359c285877d4eb4e67b74b2c4491d6d";
				batch.isSuccess = true;
				batch.transferBatchSent = true;
				batch.assetsTransfered = true;
			} catch (e) {
				console.log(e);
				batch.transferBatchSent = true;
				batch.sending = false;
				batch.error = e.toString();
			}
		},
		async transfer(batch) {
			batch.error = "";
			batch.sending = true;
			this.setTransferResendBtnInterval(batch);
			try {
				var result = await this.$parent.$parent.$parent.transactV2(
					batch.transferActions
				);
				batch.sending = false;
				if (result) {
					if (result.status == "executed") {
						clearTimeout(batch.resendInterval);
						this.$emit("show-preview-btn", false);
						batch.transferTxID = result.transaction.transaction_id;
						batch.isSuccess = true;
						batch.transferBatchSent = true;
						batch.assetsTransfered = true;
					}
				}
			} catch (e) {
				console.log(e);
				batch.transferBatchSent = true;
				batch.sending = false;
				batch.error = e.toString();
			}
		},
		setResendBtnInterval(batch) {
			batch.resendInterval = setTimeout(() => {
				batch.showResend = true;
				batch.sending = false;
			}, 8000);
		},
		setTransferResendBtnInterval(batch) {
			batch.resendInterval = setTimeout(() => {
				batch.showTransferResend = true;
				batch.sending = false;
			}, 8000);
		},

		async buildBatches() {
			this.batchData = [];
			await this.buildAnnounceLinkBatches();
		},
		async buildAnnounceLinkBatches() {
			let keyList = [];

			let emailAddresses = this.emailAddresses.split(",");
			const assetsToUseCount = emailAddresses.length;
			let assetIDs = this.assets.split(",").splice(0, assetsToUseCount);

			var i,
				j,
				tempAccountsArray,
				tempAssetIDsArray,
				counter = 1,
				chunk = parseInt(this.batchSize);
			let service = new GiftLinkService();
			for (i = 0, j = assetIDs.length; i < j; i += chunk) {
				tempAccountsArray = emailAddresses.slice(i, i + chunk);
				tempAssetIDsArray = assetIDs.slice(i, i + chunk);
				const keyResult = await service.generateKeys(
					{
						UserGuid: localStorage.getItem("userGuid"),
					},
					tempAssetIDsArray.length
				);
				if (keyResult.status == 200) {
					keyList = keyResult.data;
				}
				let batch = new BatchModel();
				batch.id = counter;
				batch.keys = keyList;
				batch.accounts = tempAccountsArray.splice(0, tempAssetIDsArray.length);
				batch.assets = tempAssetIDsArray;
				batch.actions = this.getAnnounceLinkActions(keyList, tempAssetIDsArray);
				batch.transferActions = this.getTransferActions(tempAssetIDsArray);
				batch.totalAccounts = batch.accounts.length;
				batch.sent = false;
				batch.sending = false;
				batch.isSuccess = false;
				batch.showResend = false;
				batch.resendInterval = null;
				batch.actiontype = ACTIONTYPE.AATRANSFER;
				batch.txID = null;
				if (batch.actions.length > 0) {
					this.batchData.push(batch);
				}
				counter++;
			}
			console.log(this.batchData);
		},
		getAnnounceLinkActions(keys, assetIDs) {
			let actions = [];
			assetIDs.map((ele, index) => {
				if (keys[index]) {
					actions.push(
						{
							account: "atomictoolsx",
							name: "announcelink",
							authorization: [
								{
									actor: this.accountName,
									permission: this.permission, // add permission
								},
							],
							data: {
								creator: this.accountName,
								key: keys[index].publicKey,
								asset_ids: [ele.toString()],
								memo: "",
							},
						},
						{
							account: "atomicassets",
							name: "transfer",
							authorization: [
								{
									actor: this.accountName,
									permission: this.permission,
								},
							],
							data: {
								from: this.accountName,
								to: "atomictoolsx",
								asset_ids: [ele.toString()],
								memo: "link",
							},
						}
					);
				}
			});
			return actions;
		},
		getTransferActions(assetIDs) {
			let actions = [];
			assetIDs.map((ele) => {
				actions.push({
					account: "atomicassets",
					name: "transfer",
					authorization: [
						{
							actor: this.accountName,
							permission: this.permission,
						},
					],
					data: {
						from: this.accountName,
						to: "atomictoolsx",
						asset_ids: [ele.toString()],
						memo: "link",
					},
				});
			});
			return actions;
		},
		closeLoadingModal() {
			this.showLoadingModal = false;
		},
		updateCSV() {
			let accountsArray = [];
			let assetsArray = [];
			this.batchData.forEach((b) => {
				if (!b.isSuccess) {
					b.accounts.forEach((a) => {
						accountsArray.push(a);
					});
					b.assets.forEach((a) => {
						assetsArray.push(a);
					});
				}
			});
			if (accountsArray.length > 0) {
				this.$parent.$parent.emailAddresses = accountsArray.toString();
			}
			if (assetsArray.length > 0) {
				this.$parent.$parent.assets = assetsArray.toString();
			}
		},
	},
	mounted() {
		console.log();
	},
};
</script>

<style scoped lang="scss">
$padding: 60px;

.nft-drop {
	position: relative;
	z-index: 30;

	@media screen and (min-width: $desktop) {
		margin-top: $padding;
		margin-bottom: $padding * 2;
	}

	@media screen and (max-width: $desktop) {
		overflow-x: hidden;
		overflow-y: visible;
		padding: 40px 30px;
	}

	h2 {
		margin-bottom: 25px;
		color: $link;
	}

	.columns.is-full-height {
		min-height: calc(
			100vh - #{$navbar-height} - #{$footer-height} - #{$padding}
		);

		@media screen and (max-width: 766px) {
			min-height: calc(100vh - #{$navbar-height} - 224px - 80px);
		}
	}
}
.batch-details {
	h2 {
		margin-bottom: 25px;
		color: $link;
	}

	p {
		//margin-bottom: 30px !important;
		color: $white;

		strong {
			color: $white;
		}
	}

	.label {
		margin-bottom: 15px;
		color: $white;
		line-height: 1;
	}

	button.batch-btn,
	button.create-new {
		display: inline-block;
		//margin-bottom: 80px;
		padding: 0px 35px;
		height: 40px;
		text-transform: uppercase;
		background: $gradient;
		background-repeat: no-repeat;
		font-weight: bold;
		font-size: 12px;
		border: none;
		transition: all 0.2s linear;

		&:hover {
			border: none;
			background: $gradient;
			transform: translateY(1px);
		}

		&[disabled] {
			opacity: 1;
			// background: $border;
		}
	}
}
.bloks-logo {
	max-height: 22px;
	max-width: 22px;
	margin-right: 5px;
}
</style>