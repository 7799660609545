import axios from "axios";

const API_URL = `${process.env.VUE_APP_BASE_API_URL}giftlink`;
const API_ENDPOINT_GENERATEKEYS = '/generatekeys'
const API_ENDPOINT_EMAILBLAST = '/emailblast'
const API_ENDPOINT_GETASSETBYLINKID = '/assetbylinkid'
const API_ENDPOINT_SENDGRIDLISTS = '/sendgridlists'
const API_ENDPOINT_SENDGRIDCONTACTS = '/sendgridcontacts'
const API_ENDPOINT_ASSETSBYTEMPLATE = '/assetsbytemplate'

export default class GiftLinkService {
    constructor() {

    }

    generateKeys(model,count) {
        const url = API_URL + API_ENDPOINT_GENERATEKEYS
        return axios
            .post(`${url}?count=${count}`, model)

    }

    emailBlast(model) {
        const url = API_URL + API_ENDPOINT_EMAILBLAST
        return axios
            .post(url, model)

    }

    getAssetByLinkID(linkID){
        const url = `${API_URL}${API_ENDPOINT_GETASSETBYLINKID}?linkID=${linkID}`;
        return axios.get(url)
    }

    getSendgridLists(userID){
        const url = `${API_URL}${API_ENDPOINT_SENDGRIDLISTS}?userID=${userID}`;
        return axios.get(url)
    }
    getSendgridContacts(userID,listID){
        const url = `${API_URL}${API_ENDPOINT_SENDGRIDCONTACTS}?userID=${userID}&listID=${listID}`;
        return axios.get(url)
    }
    getAssetsByTemplate(owner,templateIDs){
        const url = `${API_URL}${API_ENDPOINT_ASSETSBYTEMPLATE}?owner=${owner}&templateIDs=${templateIDs}`;
        return axios.get(url)
    }
}