export default class UserClaimModel {
    constructor() {
        this.ToAccount = ''
        this.GiftID = ''
        this.UserGuid = ''
        this.Source = ''
        this.FromIframe = false
        this.UID = null,
        this.Code = null,
        this.TransactionID = ''
    }
}