<template>
<!-- <div class="columns is-centered">
	<div class="column is-full is-centered is-mobile is-desktop is-vcentered has-text-centered"> -->
        <div v-if="isWhiteListed && isQRCampaignActive">
        <div v-if="qrCampaignTemplateID && !giftClaimed" class="claim-nft content">
            <button :disabled="showLoadingModal" @click="claim()" class="send-btn button is-medium is-rounded is-link" id="gift-claim-btn">Claim NFT</button>
        </div>
        <div v-if="giftClaimed" class="claim-nft content">
            <h2 id="claim-success-text" v-bind:style="{ color: successTextColor }" v-if="isSuccess">Claimed!</h2>
        </div>
							<!-- <div v-if="giftClaimed" class="claim-nft content">
								<h2 v-if="isSuccess">Gift has been claimed!</h2>
								<h2 v-if="!isSuccess">Oops!</h2>

								<p v-if="isSuccess">
									You have successfully claimed the gift from
									<strong>{{ userGift.fromName }}</strong>
                                    <strong v-if="qrCampaignFromName">{{qrCampaignFromName}}</strong>
                                    on <strong>{{ giftClaimDate | moment("MMM Do, YYYY") }}</strong>!
									<br>
									<a target="_blank" :href="waxTxUrl">View the blockchain transaction here</a>
								</p>

								<a
									v-if="isSuccess && !isPack"
									class="button is-link is-rounded is-outlined is-uppercase has-text-weight-bold"
									target="_blank"
									:href="getExplorerUrl()">
									See My Collections
								</a>

								<a
									v-if="isSuccess && isPack"
									class="button is-link is-rounded is-outlined is-uppercase has-text-weight-bold"
									target="_blank"
									:href="giftClient.unpackUrl">
									Click To Open
								</a>

								<p v-if="!isSuccess">
									{{ errorMessage }}
								</p>
							</div> -->
		
    </div>
<!-- </div> -->
</template>

<script>
import UserGiftService from "../core/services/user-gift.service";
import UserClaimModel from "../core/models/user-claim.model";
import { ASSETTYPE,DOMAIN } from '../core/models/constants'
import axios from "axios";

export default {
	props: {
        accountName: {
			type: String,
			required: true
        },
        giftClient:{
            type: Object,
        },
        domain:{
             type: Number,
             required: true
        },
        domainSettings:{
            type: Object
        }
    },
	watch: {
		accountName: function() {
			if (this.claimGift) {
                if(this.qrCampaignTemplateID){
                    this.mintQRCampaignGift(true)
                }
			}
        },
        giftClient:function(){
        //   if(this.userGift.assetID){
        //     this.setIsPack(this.userGift.assetID,this.giftClient.allowedTemplates)
        //   }
        }
	},
	data() {
		return {
			errors: [],
			message:
				"A gift from me, to you! I really hope you enjoy this NFT. Cheers!",
			assetImgUrl: require("@/assets/img/card-placeholder.jpg"),
			assetTemplateMint: "",
			assetName: "",
			giftID: null,
			giftClaimed: false,
			userGift: {},
			isSuccess: false,
			errorMessage: null,
            claimGift: false,
            waxTxUrl: null,
            giftClaimDate:null,
            waxOpenPackUrl: null,
            atomicHubInventory:"https://wax.atomichub.io/profile/",
            simpleAssetInventory:"https://wax.simplemarket.io/explorer/",
			isPack:false,
            showConfirmModal:false,
            confirmationModalText:"To 'claim' your NFT, simply login to your WAX account using the button below. Note, if you don't have one, you'll be given the option to create an account.",
			assetType: ASSETTYPE.AA,
			DOMAIN:DOMAIN,
			theme: this.domainSettings.WebsiteName,
            qrCampaignTemplateID:null,
            qrCampaignMsg:null,
            qrCampaignFromName:null,
            showLoadingModal: false,
            campaignSource:'',
            hasVideo:false,
            isWhiteListed: false,
            isQRCampaignActive: false,
            qrCampaignStartDate: null,
            qrCampaignEndDate: null,
            qrCampaignMaxCount: null,
            btnTextColor: null,
            btnBgColor: null,
            successTextColor: '#f268df',
		};
	},
	methods: {
        closeLoadingModal() {
			this.showLoadingModal = false;
		},
		showConfirmationModal(){
			this.showConfirmModal = true;
		},
		async claim() {
			this.showConfirmModal = false;
			this.claimGift = true;
			if (!this.accountName || (!this.giftID && !this.qrCampaignTemplateID)) {
				await this.$parent.childlogin(this.$route.name);
			} else {
                if(this.qrCampaignTemplateID){
                    this.mintQRCampaignGift(true)
                }
				
			}
		},

        mintQRCampaignGift(transferAsset = false) {
            this.showLoadingModal = true;
			if (!transferAsset) {
				return;
            }
            if(!this.accountName){
                return;
            }
			let model = new UserClaimModel();
			model.ToAccount = this.accountName;
            model.UserGuid = localStorage.getItem("userGuid");
            model.TemplateID = this.qrCampaignTemplateID
            model.Source = this.campaignSource
            model.FromIframe = true
			let service = new UserGiftService();
            this.claimGift = false;
			service
				.claimQRCampaignGift(model)
				.then((response) => {
					if (response.status == 200) {
                      this.onGiftSuccess(response)
					}
				})
				.catch(function(error) {
					console.log(error);
                    this.showLoadingModal = false;
				});
		},

        onGiftSuccess(response){
            this.giftClaimed = true;
            this.isSuccess = response.data.isSuccess;
            this.waxTxUrl = `${this.domainSettings.TxExplorerUrl}/${response.data.transactionID}`;
            this.giftClaimDate = response.data.claimDate
            this.errorMessage = response.data.errorMessage;
            this.showLoadingModal = false;
        },

        getExplorerUrl(){
            switch(this.domain){
				case DOMAIN.WAX:
					if(this.assetType == ASSETTYPE.AA){
						return `${this.domainSettings.ExplorerUrl}/nfts`
					}
					else{
						return `${this.simpleAssetInventory}main?searchString=${this.accountName}`
					}
				case DOMAIN.GYB:
                case DOMAIN.MEME:
					return `${this.domainSettings.ExplorerUrl}/collections`
                case DOMAIN.NEWDEX:
					return `${this.domainSettings.ExplorerUrl}/account`
			} 
		},
		getClaimBtnText(){
			if(this.accountName){
				return 'Claim'
			}
			switch(this.domain){
				case DOMAIN.WAX:
					return 'Login To Wax'
				case DOMAIN.GYB:
                case DOMAIN.MEME:
                case DOMAIN.NEWDEX:
					return 'Login'
			} 
		},
	},
    beforeCreate: function() {
        document.body.className = 'iframe-btn';
        document.querySelector('html').style.backgroundColor = 'transparent'
        document.querySelector('html').style.maxWidth = 'max-content'
        document.querySelector('html').style.minWidth = 'min-content'
        document.querySelector('html').style.position = 'absolute'
    },
    updated(){
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been re-rendered
            var ele =  document.getElementById('gift-claim-btn')

            if(ele){
                if(this.btnTextColor){
                ele.style.color = this.btnTextColor
            }
            if(this.btnBgColor){
                ele.style.background = this.btnBgColor
            }
            }
        }) 
    },
	mounted() {
        //console.log(document.referrer)
        axios.get(`${process.env.VUE_APP_BASE_API_URL}members/iswhitelisted?url=${document.referrer}`).then((res) =>{
            this.isWhiteListed = res.data
        })
        
        this.qrCampaignTemplateID = this.$route.query.t
        this.qrCampaignStartDate = this.$route.query.sdate
        this.qrCampaignEndDate = this.$route.query.edate
        this.qrCampaignMaxCount = this.$route.query.maxcount
        this.btnTextColor = this.$route.query.btntextcolor
        this.btnBgColor = this.$route.query.btnbgcolor
        this.successTextColor = this.$route.query.btnbgcolor

        this.campaignSource = this.$route.query.source
        let service = new UserGiftService();
		
        if(this.qrCampaignTemplateID){
            service.getTemplateByID(this.qrCampaignTemplateID,this.qrCampaignStartDate,this.qrCampaignEndDate,this.qrCampaignMaxCount).then((res)=>{
                if(res.status == 200){
                    let data = res.data.template
                    this.assetImgUrl = data.imgUrl ? data.imgUrl : 'https://giftnft.s3.amazonaws.com/card-placeholder.jpg';
                    this.hasVideo = data.hasVideo
                    this.assetName = data.templateName
                    this.qrCampaignFromName = data.collectionName
                    this.qrCampaignMsg = res.data.campaignMessage
                    this.isQRCampaignActive = data.isActive
                }

            })
        }
	},
};
</script>
<style lang="scss">
body.iframe-btn {
    background: transparent !important;
    max-width: max-content !important;
    &:before {
	height: 0px !important;
    background: transparent !important;
    }
}
html.giftnft{
    overflow-y: hidden !important;
}
</style>

<style scoped lang="scss">

$padding: 60px;
.claim {
	position: relative;
	z-index: 30;

	@media screen and (min-width: $desktop) {
		//margin-top: $padding;
		//margin-bottom: $padding * 2;
	}

	@media screen and (max-width: $desktop) {
		//padding: 40px 0;
	}

	@media screen and (max-width: 766px) {
		//overflow-x: hidden;
		//overflow-y: visible;
		//padding: 40px 30px;
	}
}

.columns.is-full-height {
	min-height: calc(100vh - #{$navbar-height} - #{$footer-height} - #{$padding});
}

.login-required {
	position: relative;

	h1,
	p {
		margin-bottom: 30px !important;
		color: $white;
	}

	p {
		margin-bottom: 35px !important;
	}
}

.claim-nft {
	@media screen and (max-width: 766px) {
		//margin-top: 30px;
	}

	h2 {
		margin-top: 10px !important;
		margin-bottom: 25px;
		color: $link;
	}

	p {
		margin-bottom: 30px !important;
		color: $white;

		strong {
			color: $white;
		}
	}

	.label {
		margin-bottom: 5px;
		color: $white;
		line-height: 1;
	}

	button {
		display: inline-block;
		padding: 0px 30px;
		height: 46px;
		text-transform: uppercase;
		//background: $gradient;
		background-repeat: no-repeat;
		font-weight: bold;
		font-size: $body-size;
		border: none;
		transition: all 0.2s linear;
		margin-top: 10px !important;

		&:hover {
			border: none;
			background: $gradient;
			transform: translateY(1px);
		}
	}

	.sent-from {
		margin-bottom: 30px;

		p {
			margin: 0 !important;
			text-transform: uppercase;

			span {
				display: inline-block;
				width: 80px;
				font-weight: bold;
				color: $link;
			}
		}
	}
}
</style>