<template>
	<div>
		<div class="confirm-send modal" :class="{ 'is-active': showLoginModal }">
			<div class="modal-background" @click="closeLoginModal()"></div>
			<div class="modal-card">
				<header class="modal-card-head is-radiusless">
					<p class="modal-card-title has-text-weight-bold">Login via...</p>
					<button
						@click="closeLoginModal()"
						class="delete"
						aria-label="close"
					></button>
				</header>
				<section class="modal-card-body">
					<button
						@click="openHashpackModal()"
						class="
							button
							is-rounded is-medium is-outlined
							has-text-weight-bold
							is-fullwidth
						"
					>
						<img src="@/assets/img/hashpack-logo.svg" />
					</button>
					<button
						v-if="isDesktop"
						@click="bladeLogin()"
						class="
							button
							is-rounded is-medium is-outlined
							has-text-weight-bold
							is-fullwidth
						"
					>
						<img src="@/assets/img/blade-logo.svg" />
					</button>
					<div v-if="showArkane">
						<button
							@click="venlyLogin('google')"
							class="
								button
								is-rounded is-medium is-outlined
								has-text-weight-bold
								is-fullwidth
							"
						>
							<img
								src="@/assets/img/google-logo.svg"
								style="max-height: 32px"
							/>
						</button>
						<button
							@click="venlyLogin('facebook')"
							class="
								button
								is-rounded is-medium is-outlined
								has-text-weight-bold
								is-fullwidth
							"
						>
							<img
								src="@/assets/img/facebook-logo.svg"
								style="max-height: 23px"
							/>
						</button>
						<button
							@click="venlyLogin('twitter')"
							class="
								button
								is-rounded is-medium is-outlined
								has-text-weight-bold
								is-fullwidth
							"
						>
							<img src="@/assets/img/twitter-logo.svg" />
						</button>
					</div>
				</section>
			</div>
		</div>

		<div class="confirm-send modal" :class="{ 'is-active': showErrorModal }">
			<div class="modal-background" @click="closeErrorModal()"></div>
			<div class="modal-card">
				<header class="modal-card-head is-radiusless">
					<p class="modal-card-title has-text-weight-bold">Error</p>
					<button
						@click="closeErrorModal()"
						class="delete"
						aria-label="close"
					></button>
				</header>
				<section class="modal-card-body">
					<p class="has-text-weight-bold">
						{{ errorMessage }}
						<span>Find out how <a target="_blank">here.</a></span>
					</p>
					<br />
					<button
						class="
							button
							is-primary is-rounded is-outlined
							has-text-weight-bold
						"
						@click="openLoginModal(view)"
					>
						Try Again
					</button>
				</section>
			</div>
		</div>

		<HashpackLoginModal
			v-if="pairingString"
			ref="hashpackModal"
			:pairingString="pairingString"
			@hashpack-chrome-login="hashpackLogin"
		></HashpackLoginModal>
	</div>
</template>

<script>
import { DOMAIN } from "../core/models/constants";
import { HashConnect } from "hashconnect";
import { HashConnectConnectionState } from "hashconnect/dist/types";
import HashpackLoginModal from "./HashpackLoginModal.vue";
import axios from "axios";
import { SecretType, VenlyConnect } from "@venly/connect";
import { HEDERAWALLET } from "../core/models/constants";
import DeviceDetector from "device-detector-js";
import {
	BladeSigner,
	BladeWalletError,
	HederaNetwork,
} from "@bladelabs/blade-web3.js";
import {
	TransferTransaction,
	TransactionId,
	AccountId,
	TokenAssociateTransaction,
} from "@hashgraph/sdk";

export default {
	components: {
		HashpackLoginModal,
	},
	props: {
		domain: {
			type: Number,
			required: true,
		},
		domainSettings: {
			type: Object,
		},
	},
	name: "GybLogin",
	data() {
		return {
			//arkane
			venlyConnect: null,
			walletID: null,
			showArkane: true,

			//shared
			showLoginModal: false,
			showErrorModal: false,
			errorMessage: null,
			walletAddress: null,
			view: null,
			DOMAIN: DOMAIN,
			isMainnet: process.env.VUE_APP_HEDERA_ISMAINNET == "true" ? true : false,
			provider: null,

			//blade
			bladeLoaded: false,
			bladeNotFound: false,
			bladeSigner: BladeSigner,
			bladeFirstLoad: true,
			isDesktop: false,

			//hashpack
			hashconnect: HashConnect,
			appMetadata: {
				name: "bountyblokio",
				description:
					"Run engaging Web3 driven contests and giveaways that convert",
				icon: "https://www.hashpack.app/img/logo.svg",
			},
			availableExtension: null,

			state: HashConnectConnectionState.Disconnected,
			topic: String,
			pairingString: null,
			pairingData: null,
		};
	},
	methods: {
		openHashpackModal() {
			this.$refs.hashpackModal.openHashpackModal();
		},
		async hashpackLogin() {
			if (this.pairingData) {
				this.onHashpackLogin(this.pairingData);
				return;
			}
			this.hashconnect.connectToLocalWallet();
		},
		openLoginModal(view) {
			this.view = view;
			if (this.walletAddress) {
				this.$emit("update-accountname", this.walletAddress);
				if (view && this.$route.name != "Gift") {
					//this.$router.push(this.view);
					this.$router.push({
						path: this.view,
						query: this.$route.query,
					});
				}
			} else {
				this.showLoginModal = true;
			}
		},
		closeLoginModal() {
			this.showLoginModal = false;
		},
		closeErrorModal() {
			this.showErrorModal = false;
		},
		async initHashconnect() {
			this.hashconnect = new HashConnect(true);

			//register events
			this.setUpHashConnectEvents();
			//initialize and use returned data
			let initData = await this.hashconnect.init(
				this.appMetadata,
				this.isMainnet ? "mainnet" : "testnet",
				false
			);

			this.topic = initData.topic;
			this.pairingString = initData.pairingString;

			//Saved pairings will return here, generally you will only have one unless you are doing something advanced
			if (initData.savedPairings[0]) {
				this.pairingData = initData.savedPairings[0];
			}
		},
		setUpHashConnectEvents() {
			//This is fired when a extension is found
			this.hashconnect.foundExtensionEvent.on((data) => {
				console.log("Found extension", data);
				this.availableExtension = data;
			});

			//This is fired when a wallet approves a pairing
			this.hashconnect.pairingEvent.on((data) => {
				console.log("Paired with wallet", data);
				this.pairingData = data.pairingData;
				this.onHashpackLogin(this.pairingData);
			});

			//This is fired when HashConnect loses connection, pairs successfully, or is starting connection
			this.hashconnect.connectionStatusChangeEvent.on((state) => {
				console.log("hashconnect state change event", state);
				this.state = state;
			});
		},
		onHashpackLogin(pairingData) {
			this.provider = HEDERAWALLET.HASHPACK;
			this.walletAddress = pairingData.accountIds[0];
			this.onLoginSuccess();
		},
		venlyLogin(type) {
			this.provider = HEDERAWALLET.VENLY;
			this.venlyConnect.checkAuthenticated().then((re) => {
				console.log("is auth", re);
				//if(re.isAuthenticated){
				// this.getWallets()
				// }
				//else{
				this.venlyConnect.flows
					.authenticate({ windowMode: "POPUP", idpHint: type })
					.then((result) => {
						result
							.authenticated((auth) => {
								console.log(auth);
								//this.arkaneConnect.flows.manageWallets('ETHEREUM').then((res) =>{
								//console.log(res)
								this.getWallets();
								//})
							})
							.notAuthenticated((auth) => {
								alert("not logged in", auth);
							});
					});
				//}
			});
		},
		getWallets() {
			this.venlyConnect.api
				.getWallets({ secretType: SecretType.HEDERA })
				.then((result) => {
					if (result.length > 0) {
						this.walletAddress = result[0].address;
						this.walletID = result[0].id;
						this.onLoginSuccess();
					} else {
						this.venlyConnect.flows
							.getAccount(SecretType.HEDERA)
							.then((account) => {
								this.walletAddress = account.wallets[0].address;
								this.walletID = account.wallets[0].id;
								this.onLoginSuccess();
							});
					}
				});
		},
		async bladeLogin() {
			this.provider = HEDERAWALLET.BLADE;
			const signer = new BladeSigner();
			const params = {
				network: this.isMainnet ? HederaNetwork.Mainnet : HederaNetwork.Testnet,
				// dAppCode - optional while testing, request specific one by contacting us.
				dAppCode: "bountyblok",
			};
			// create session with optional parameters.
			try {
				console.log(signer);
				await signer.createSession(params);
				console.log(signer);
				// bladeSigner object can now be used.
				//signer.getAccountId();
				const accountID = signer?.getAccountId() ?? null;
				this.walletAddress = accountID.toString();
				this.bladeSigner = signer;
				this.onLoginSuccess();
			} catch (err) {
				this.bladeLoaded = false;
				if (this.bladeFirstLoad) {
					this.bladeFirstLoad = false;
					await this.bladeLogin();
					return;
				}
				if (err instanceof Error) {
					if (err.name === BladeWalletError.ExtensionNotFound) {
						console.log(`Blade extension not found.`);
						this.bladeNotFound = true;
					} else if (err.name === BladeWalletError.NoSession) {
						console.log(`No active blade session.`);
					} else if (err.message === `The user's wallet is locked.`) {
						console.warn(`User wallet is locked.`);
					} else {
						console.log(`Uncaught: ${err.message}`);
					}
				}
			}
		},
		onLoginSuccess() {
			axios
				.post(`${process.env.VUE_APP_BASE_API_URL}members/authenticate`, {
					username: this.walletAddress,
				})
				.then((response) => {
					if (response.status == 200) {
						this.closeLoginModal();
						localStorage.setItem("userGuid", response.data.userGuid);
						localStorage.setItem("hedera-account", this.walletAddress);
						localStorage.setItem("hedera-provider", this.provider);
						this.$emit("update-accountname", this.walletAddress);
						if (this.provider == HEDERAWALLET.VENLY) {
							localStorage.setItem("venly-wallet-id", this.walletID);
						}
						if (this.$route.query.returnUrl) {
							this.$router.push(this.$route.query.returnUrl);
						} else if (this.view) {
							this.$router.push(this.view);
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		async transact(data) {
			if (this.provider == HEDERAWALLET.VENLY) {
				const signer = this.venlyConnect.createSigner();
				let result = await signer.executeNftTransfer({
					walletId: this.walletID,
					to: process.env.VUE_APP_HEDERA_ACCOUNT,
					tokenAddress: data.tokenID,
					tokenId: data.serialNo,
					secretType: SecretType.HEDERA,
				});
				console.log(result);
				if (result) {
					return result.result.transactionHash;
				}
			} else {
				let _transaction = await new TransferTransaction();
				console.log(this.walletAddress, process.env.VUE_APP_HEDERA_ACCOUNT);
				_transaction.addNftTransfer(
					data.tokenID,
					data.serialNo,
					this.walletAddress,
					process.env.VUE_APP_HEDERA_ACCOUNT
				);
				_transaction.setTransactionMemo("giftnft");
				if (this.provider == HEDERAWALLET.HASHPACK) {
					let transactionBytes = await this.makeBytes(
						_transaction,
						this.walletAddress
					);
					const transaction = {
						topic: this.topic,
						byteArray: transactionBytes,

						metadata: {
							accountToSign: this.walletAddress,
							returnTransaction: false,
							hideNft: false,
						},
					};

					let res = await this.hashconnect.sendTransaction(
						this.topic,
						transaction
					);
					if (res.success) {
						var resObj = res.response;
						let txID = this.parseHederaTxID(resObj.transactionId);
						return txID;
					} else {
						return null;
					}
				} else {
					const result = await this.bladeSigner.call(_transaction);
					let txID = result?.transactionId.toString();
					return this.parseHederaTxID(txID);
				}
			}
		},
		async associateHederaToken(tokenID) {
			var isSuccess = false;
			var errorMessage = ''
			var tokenIDArr = [];
			tokenIDArr.push(tokenID);
			if (this.provider == HEDERAWALLET.VENLY) {
				const signer = this.venlyConnect.createSigner();
				let result = await signer.executeNativeTransaction({
					walletId: this.walletID,
					type: "HEDERA_TOKEN_ASSOCIATION",
					tokenIds: tokenIDArr,
					secretType: SecretType.HEDERA,
				});
				console.log(result);
				if (result) {
					isSuccess = true;
				}
			} else {
				let trans = await new TokenAssociateTransaction();
				trans.setTokenIds(tokenIDArr);
				console.log(this.walletAddress.toString());
				trans.setAccountId(this.walletAddress.toString());
				if (this.provider == HEDERAWALLET.HASHPACK) {
					let transBytes = await this.makeBytes(trans, this.walletAddress);
					const transaction = {
						topic: this.topic,
						byteArray: transBytes,

						metadata: {
							accountToSign: this.walletAddress,
							returnTransaction: false,
							hideNft: false,
						},
					};
					try {
						let res = await this.hashconnect.sendTransaction(
							this.topic,
							transaction
						);
						console.log(res);
						if (res.success) {
							isSuccess = true;
						} else {
							if (res.error.status == "TOKEN_ALREADY_ASSOCIATED_TO_ACCOUNT") {
								isSuccess = true;
							}
							else if (res.error.status == "INSUFFICIENT_PAYER_BALANCE") {
								errorMessage = res.error.message
								isSuccess = false;
							}
						}
					} catch (e) {
						console.log(e);
					}
				} else {
					try {
						const result = await this.bladeSigner.call(trans);
						console.log("result", result);
						isSuccess = true;
					} catch (e) {
						var eObj = JSON.parse(e)
						errorMessage = eObj.message
					}
				}
			}

			return {isSuccess: isSuccess , errorMessage: errorMessage};
		},
		async makeBytes(trans, signingAcctId) {
			let transId = TransactionId.generate(signingAcctId);
			trans.setTransactionId(transId);
			trans.setNodeAccountIds([new AccountId(3)]);

			await trans.freeze();

			let transBytes = trans.toBytes();

			return transBytes;
		},
		parseHederaTxID(txID) {
			if (txID) {
				const chunks = txID.split("@");
				return `${chunks[0]}-${chunks[1].replace(".", "-")}`;
			} else {
				return "";
			}
		},
		logout() {
			if (this.provider == HEDERAWALLET.VENLY) {
				this.venlyConnect.checkAuthenticated().then((re) => {
					if (re.isAuthenticated) {
						this.venlyConnect.logout({ windowMode: "POPUP" });
						this.onLogoutSuccess();
					}
				});
			} else if (this.provider == HEDERAWALLET.HASHPACK) {
				this.hashconnect.clearConnectionsAndData();
				this.pairingData = null;
			} else if (this.provider == HEDERAWALLET.BLADE) {
				this.bladeSigner.killSession().then((res) => {
					console.log("wallet disconnected", res);
				});
			}
			this.onLogoutSuccess();
		},
		onLogoutSuccess() {
			this.walletAddress = "";
			this.$emit("update-accountname", this.ethAddress);
			localStorage.removeItem("userGuid");
			localStorage.removeItem("hedera-provider");
			localStorage.removeItem("hedera-account");
			if (this.provider == HEDERAWALLET.VENLY) {
				localStorage.removeItem("venly-wallet-id");
			}
			if (this.$route.name == "Gift") {
				this.$router.push({
					path: `/`,
					query: { id: this.$route.query.id },
				});
			}
		},
	},
	async mounted() {
		const deviceDetector = new DeviceDetector();
		const device = deviceDetector.parse(navigator.userAgent);
		if (device.device.type == "desktop") {
			this.isDesktop = true;
		}
		await this.initHashconnect();
		this.venlyConnect = new VenlyConnect("bountyblok", {
			environment: this.isMainnet ? "prod" : "staging",
			windowMode: "POPUP",
		}); // for staging , rinkeby network etc

		this.provider = localStorage.getItem("hedera-provider");
		if (this.provider) {
			if (this.provider == HEDERAWALLET.HASHPACK) {
				await this.hashpackLogin();
			} else if (this.provider == HEDERAWALLET.VENLY) {
				this.venlyConnect.checkAuthenticated().then((re) => {
					if (re.isAuthenticated) {
						this.getWallets();
					}
				});
			} else if (this.provider == HEDERAWALLET.BLADE) {
				await this.bladeLogin();
			}
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-card {
	border-radius: $radius;

	@media screen and (min-width: $tablet) {
		width: 320px;
	}

	.button {
		margin: 0;
		color: $text;
		// text-transform: uppercase;
		// font-size: 18px;

		+ .button,
		+ div > .button {
			margin-top: 10px;
		}

		img {
			display: inline-block;
			max-height: 26px;
			vertical-align: middle;
		}

		&:hover,
		&:focus {
			border-color: $text;
			color: $text;
			transition: all 0.2s linear;
		}
	}
}
</style>
